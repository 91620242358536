.services-area-home-3 {
  position: relative;
  padding: 100px 20px 100px;
}
.blur-1 {
  fill: violet;
  filter: blur(250px);
  z-index: 1;
  width: 384px;
  height: 399px;
}
.services-area-home-3.services-area .services-page-services-area-shep-1 {
  position: absolute;
  left: 0;
  top: 150px;
}
.services-area-home-3 .services-name {
  margin-bottom: 50px;
  cursor: pointer;
}
.other_services .font-size-1-40 {
  font-size: 40px;
}
.services-area-home-3 h4 {
  font-style: normal;
  font-weight: 700;
  line-height: 40px;
  letter-spacing: -0.8px;
  color: #ccdeff;
  /* font-family: Nunito, sans-serif; */
  margin-bottom: 10px;
}
.services-area-home-3 .services-name .services-info {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 35px;
  border-bottom: 1px solid rgba(216, 208, 199, 0.2);
}
.other_services .font-size-1-16 {
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 26px;
  /* font-family: Jost, sans-serif; */
  color: #ccdeff;
  max-width: 80%;
}
.services-area-home-3 p {
  font-style: normal;
  font-weight: 400;
  line-height: 26px;
  /* font-family: Jost, sans-serif; */
  color: #ccdeff;
}
.services-area-home-3 .services-name .services-info a {
  width: 65px;
  height: 65px;
  display: block;
  text-align: center;
  line-height: 65px;
  background: linear-gradient(-45deg, #008cff, #ec3258);
  border-radius: 50%;
  transition-timing-function: linear;
  transition-duration: 0.3s;
}
.services-area-home-3 .services-name .services-info a svg {
  rotate: 135deg;
}
.services-area-home-3 .services-name {
  margin-bottom: 50px;
  cursor: pointer;
}
.other_services .title {
  text-align: center;
}
.services-area-home-3 .services-banner .title {
  text-align: left;
}
.other_services .title .sub-title {
  margin-bottom: 15px;
}
.other_services .title .sub-title p {
  margin: 0;
  padding: 0;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  /* font-family: Nunito, sans-serif; */
  color: violet;
  text-transform: uppercase;
}
.other_services .title .main-title h3 {
  margin: 0;
  padding: 0;
  font-size: 50px;
  font-style: normal;
  font-weight: 700;
  /* font-family: Nunito, sans-serif; */
  color: #ccdeff;
  position: relative;
  display: inline;
}
.other_services .absolute {
  position: absolute !important;
}
.other_services .title .main-title h3 span {
  position: absolute;
  right: 11px;
  bottom: 0;
  /* z-index: -1; */
}
.other_services img {
  max-width: 100%;
  height: auto;
  display: block;
}
.services-area-home-3 .services-banner .services-banner-inner {
  margin-top: 58px;
  display: flex;
  gap: 20px;
}
.services-area-home-3 .services-banner .services-banner-inner .services-img {
  overflow: hidden;
}
.services-area-home-3
  .services-banner
  .services-banner-inner
  .services-img:first-child {
  margin-top: 20px;
}
.other_services_row{
  justify-content: space-between;
}
@media (max-width:768px){
  .other_services_row{
    justify-content: center;
    flex-direction: column-reverse;
    gap: 40px;
  }
}