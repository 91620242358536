.ts-page-wrapper {
    height: 100%;
    padding: 100px 0px;
    transition: 1s cubic-bezier(.77, 0, .18, 1);
    color: #fff !important;
    height: 80vh;
}
.ts-page-wrapper img{
    width: 250px;
}
#main-content {
    display: table;
    height: 100%;
    width: 100%;
}
#main-content .ts-content-wrapper {
    display: table-cell;
    vertical-align: middle;
    padding-top: 30px;
}
.comming-soom-back{
    background-color: #fff;
}
#main-content .ts-content-wrapper > .row {
    width: 100%;
}
.ts-theme-dark p {
    color: rgba(255, 255, 255, .6);
    line-height: 1.6875rem;
    margin-bottom: 1.875rem;
    font-weight: 400;
    font-size: 1.125rem
}
.text-center {
    text-align: center !important;
}
.ts-count-down {
    display: flex;
    justify-content: space-between;
    margin-bottom: 1.25rem;
    font-size: 2.5rem;
    font-weight: 400;
    text-shadow: .375rem .3125rem 1.5625rem rgba(0, 0, 0, .5);
}
@media (min-width: 75rem) {
    .ts-count-down {
        font-size: 4.375rem;
    }
}
.ts-count-down div {
    display: block;
    width: 100%;
    position: relative;
}
.ts-count-down div .ts-cc-number {
    position: relative;
    width: 100%;
    display: block;
}
.ts-count-down div .ts-cc-number:after {
    bottom: 0;
    content: "\f111";
    font-weight: 900;
    /* font-family: "Font Awesome\ 5 Free"; */
    position: absolute;
    height: .625rem;
    margin: auto;
    font-size: .625rem;
    top: 0;
    opacity: .2;
    right: -0.625rem;
    -webkit-font-smoothing: antialiased;
}
.ts-count-down .ts-cc-description {
    display: block;
    font-size: .875rem;
    opacity: .5;
}
@media (min-width: 75rem) {
    .ts-count-down .ts-cc-description {
        font-size: 1.125rem;
    }
}
.ts-count-down .ts-cc-description {
    display: block;
    font-size: .875rem;
    opacity: .5;
}
@media (min-width: 75rem) {
    .ts-count-down .ts-cc-description {
        font-size: 1.125rem;
    }
}
.position-fixed {
    position: fixed !important;
}
.ts-background {
    bottom: 0;
    left: 0;
    height: 100%;
    overflow: hidden;
    position: absolute;
    width: 100%;
    z-index: -2;
}
.ts-shape {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    transform: rotate(.0002deg);
}
.ts-animate {
    animation-iteration-count: infinite;
    animation-duration: 5s;
}
.ts-scale {
    animation-name: ts-scale;
    animation-timing-function: ease-in-out;
}
.ts-background-image, .ts-img-into-bg {
    background-repeat: no-repeat;
    background-size: cover;
    background-position: 50%;
    height: 100%;
    overflow: hidden;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
}

.ts-bounce {
    animation-name: ts-bounce;
    animation-timing-function: ease-in-out;
}

@keyframes ts-scale {
    0% {
        transform: scale(1);
    }
    
    50% {
        transform: scale(1.05);
    }
    100% {
        transform: scale(1);
    }
}
@keyframes ts-bounce {
    0% {
        transform: translateY(0);
    }
    
    50% {
        transform: translateY(2.5rem);
    }
    100% {
        transform: translateY(0);
    }
}