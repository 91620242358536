.position-relative {
  position: relative !important;
}
section {
  background-size: cover;
  background-position: center;
}
.section {
  padding: 6em 2em 6em 2em;
  overflow: hidden;
}
.image-overlay {
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  position: absolute;
  background-color: black;
  opacity: 0.6;
  transition: background 0.3s, border-radius 0.3s, opacity 0.3s;
}
.h-100 {
  height: 100% !important;
}
.r-container {
  max-width: 1440px;
  margin-right: auto;
  margin-left: auto;
}
.r-container .banner_h1 {
  font-size: 65px;
  color: #fff;
}
.font-1 {
  /* font-family: "Montserrat", sans-serif; */
}
/* .accent-color {
    color: #e12929;
} */
.text-gray {
  color: #c2c2c2 !important;
}
.button {
  padding-inline: 17px;
  padding-block: 15px;
  text-decoration: none;
  transition: all 0.5s;
  background-color: #e12929;
  border: solid 2px #e12929;
  font-weight: 600;
  color: #fff;
}
.ls-2 {
  letter-spacing: 2px;
}
.request-loader {
  position: relative;
  height: 60px;
  width: 60px;
  border-radius: 50% !important;
  border: solid 2px #e12929;
  background-color: #e12929;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  font-size: 25px;
}
.request-loader::after,
.request-loader::before {
  opacity: 0.2;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  position: absolute;
  content: "";
  height: 100%;
  width: 100%;
  color: #e12929;
  border: 4px solid currentColor;
  border-radius: 50%;
  animation-name: ripple;
  animation-iteration-count: infinite;
  animation-timing-function: cubic-bezier(0.65, 0, 0.34, 1);
  z-index: 0;
}
.request-loader::before {
  animation-delay: 0.2s;
  animation-duration: 3s;
}
.request-loader::after,
.request-loader::before {
  opacity: 0.2;
  display: flex;
  top: 9px;
  left: 19px;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  position: absolute;
  content: "";
  height: 82%;
  width: 77%;
  color: #e12929;
  border: 4px solid currentColor;
  border-radius: 50%;
  animation-name: ripple;
  animation-iteration-count: infinite;
  animation-timing-function: cubic-bezier(0.65, 0, 0.34, 1);
  z-index: 0;
}
.request-loader::after {
  animation-delay: 0.5s;
  animation-duration: 3s;
}

@keyframes ripple {
  0% {
    opacity: 1;
    transform: scale3d(1, 1, 1);
    transform-origin: center;
    border-width: 0px;
  }
  100% {
    opacity: 0;
    transform: scale3d(1.7, 1.7, 1.8);
    transform-origin: center;
    border-width: 13px;
  }
}

/* Banner Strip   */
.bg-accent-color {
  background-color: #fff;
}
.banner-section-container {
  height: 94vh;
}
@media ((max-width: 1400px) and (min-width:1200px)) {
  .banner-section-container {
    height: 96vh;
  }
  .r-container h1 {
    font-size: 60px;
    color: #fff;
  }
}
.small-text-22 {
  color: #000;
  text-align: center;
  letter-spacing: 1.5px;
  padding-bottom: 0;
  font-size: 12px;
  margin-bottom: 4px;
  font-weight: 800;
}

@media (max-width: 768.9px) {
  .r-container h1 {
    font-size: 30px;
  }
  .r-container .fs-5 {
    font-size: 0.9rem !important;
  }
  .section {
    padding: 10rem 1rem 5rem 1rem;
  }
  .hero_banner {
    padding: 6rem 1rem 5rem 1rem;
  }
  .banner-section-container {
    height: 85vh;
  }
  /* .order-sm-2 {
    order: 2;
  }
  .order-sm-3 {
    order: 3;
  } */
}

@media (min-width: 768px) {
  .banner-section-container {
    padding-top: 12rem !important;
  }
}

@media (max-width: 768px) {
  .r-container .banner_h1 {
    font-size: 40px;
  }
}
