.sk__features-section.text-center {
  text-align: center !important;
}
@media (min-width: 576px) {
  .sk__features-section.text-sm-start {
    text-align: left !important;
  }
}
.sk__features-section.sk__py-s,
.sk__features-section .sk__pt-s {
  padding-top: 100px;
}
.sk__features-section.sk__py-s,
.sk__features-section .sk__pb-s {
  padding-bottom: 50px;
}
.sk__features-section.dark-shade-3-bg {
  background-color: #0a0a0a !important;
}
.sk__features-section .sk__heading-spacer-s {
  padding-bottom: 60px;
}
.sk__features-section h4.h4-shadow {
  text-shadow: -4.34px 9.76px 3.25px rgba(0, 0, 0, 0.75);
}
.sk__features-section .fat-divider {
  display: block;
  width: 100%;
  height: 5px;
  margin-bottom: 25px;
}
.sk__features-section .dark-shade-4-bg {
  background: linear-gradient(
    90deg,
    rgba(129, 21, 255, 1) 0%,
    rgba(255, 42, 208, 1) 50%,
    rgba(255, 143, 42, 1) 100%
  ) !important;
  background: linear-gradient(
    90deg,
    black 0%,
    rgba(0, 0, 0, 0) 10%,
    #8115ff 30%,
    #ff2ad0 50%,
    #ff8f2a 70%,
    rgba(0, 0, 0, 0) 90%,
    black 100%
  ) !important;
}
.sk__features-section .sk__features {
  padding-bottom: 70px;
  margin-left: -2px;
  margin-right: -2px;
}
.sk__features-section .sk__feature-col {
  display: flex;
  /* margin: 20px 0px; */
  padding: 20px 20px !important;
}
/* @media (min-width: 768px) {
  .sk__features-section .col-md-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
} */
.featuresDiv {
  padding: 0.2rem;
  background: linear-gradient(90deg, #8115ff 0, #ff2ad0 50%, #ff8f2a);
  border-radius: 20px;
}
.sk__features-section .sk__feature,
.sk__features-section .sk__feature-clean {
  /* position: relative;
  overflow: hidden;
  text-align: center;
  width: 100%;
  -webkit-transition: all 0.3s ease 0s;
  -o-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
  padding: 20px;
  border: 3px solid transparent;
  border-image: linear-gradient(
    to right,
    rgba(129, 21, 255, 1) 0%,
    rgba(255, 42, 208, 1) 50%,
    rgba(255, 143, 42, 1) 100%
  );
  border-image-slice: 1; */
  border-image-slice: 1;
  overflow: hidden;
  padding: 20px;
  position: relative;
  text-align: center;
  transition: all 0.3s ease 0s;
  width: 100%;
  height: 100%;
  background: #000;
  border-radius: 20px;
}
@media (min-width: 1200px) and (max-width: 1399px) {
  .sk__features-section .sk__feature {
    padding: 60px 46px 46px 46px;
  }
}
.sk__features-section .sk__feature:before {
  /* content: ""; */
  position: absolute;
  top: -6px;
  left: -6px;
  width: 12px;
  height: 12px;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
  background-color: #898989;
  background-color: rgba(255, 255, 255, 0.27);
}
.sk__features-section .colorline-deco {
  position: relative;
  width: 100%;
  height: 1px;
  margin-top: 15px;
  margin-bottom: 13px;
}
.sk__features-section .sk__absolute {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.sk__features-section .colorline-deco-hover {
  -webkit-transition: all 1s ease 0s;
  -o-transition: all 1s ease 0s;
  transition: all 1s ease 0s;
  /* opacity: 0; */
}
.sk__features-section .colorline-deco-normal {
  background-color: rgba(255, 255, 255, 0.15);
  -webkit-transition: all 1s ease 0s;
  -o-transition: all 1s ease 0s;
  transition: all 1s ease 0s;
  opacity: 1;
}
.sk__features-section .sk__gradient-back-v1,
.sk__features-section .sk__gradient-back-v1-hover:hover,
.sk__features-section .btn:not([class*="btn-"]):before,
.sk__features-section .btn-gradient:before,
.sk__features-section .btn-gradient-outline,
.sk__features-section .btn-gradient-outline:before,
.sk__features-section .btn-gradient-outline-hard,
.sk__features-section .btn-gradient-outline-hard:before,
.sk__features-section span.sk__underliner:before {
  background: rgb(129, 21, 255);
  background: -o-linear-gradient(
    left,
    rgba(129, 21, 255, 1) 0%,
    rgba(255, 42, 208, 1) 50%,
    rgba(255, 143, 42, 1) 100%
  );
  background: -webkit-gradient(
    linear,
    left top,
    right top,
    from(rgba(129, 21, 255, 1)),
    color-stop(50%, rgba(255, 42, 208, 1)),
    to(rgba(255, 143, 42, 1))
  );
  background: linear-gradient(
    90deg,
    rgba(129, 21, 255, 1) 0%,
    rgba(255, 42, 208, 1) 50%,
    rgba(255, 143, 42, 1) 100%
  );
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#8115ff",endColorstr="#ff8f2a",GradientType=1);
}
.sk__features-section .sk__features p {
  color: #fff;
}
.sk__features-section .sk__features h5 {
  color: #fff;
  font-weight: 700;
}
.sk__features-section .features-subtext-deco {
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
  height: 1px;
  background-color: #5c5c5c;
  background-color: rgba(255, 255, 255, 0.22);
  margin-right: 15px;
  margin-top: 8px;
}
@media (min-width: 576px) {
  .sk__features-section .text-sm-end {
    text-align: right !important;
  }
}
.sk__features-section .sk__features-subtext {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
.sk__features-section .features-subtext-p {
  max-width: 90%;
  width: 320px;
}
.sk__features-section p.p-xs {
  /* font-family: "Poppins", sans-serif; */
  /* font-size: 10px; */
  font-size: 11px;
  font-weight: 300;
  line-height: 19px;
  color: #ffffff;
}
.services_list_heading {
  font-size: 45px;
  text-align: center;
}
@media (max-width: 768px) {
  .services_list_heading {
    font-size: 29px;
    text-align: center;
  }
}
