.media {
  display: flex;
  align-items: flex-start;
}
.card {
  margin-bottom: 1.875rem;
  background-color: #fff;
  transition: all 0.5s ease-in-out;
  position: relative;
  border: 0rem solid transparent;
  border-radius: 0.75rem;
  box-shadow: 0rem 0.3125rem 0.3125rem 0rem rgba(82, 63, 105, 0.05);
  /* height: calc(100% - 30px); */
}
.app-stat .media span {
  height: 5.3125rem;
  width: 5.3125rem;
  border-radius: 3.125rem;
  padding: 0.625rem 0.75rem;
  font-size: 2rem;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #464a53;
  min-width: 5.3125rem;
  background-color: rgba(255, 255, 255, 0.25);
  color: #fff;
}
@media only screen and (max-width: 1600px) {
  .app-stat .media span {
    height: 3.3125rem;
    width: 3.3125rem;
    font-size: 1rem;
    min-width: 3.3125rem;
  }
}
.media-body {
  flex: 1;
}
.p-4 {
  padding: 1.5rem !important;
}
