.modal{
    z-index: 9999;
}

.popup-modal .modal-body {
  position: relative;
  padding: 0;
}

.popup-modal .close {
  position: absolute;
  top: 0px;
  right: 0px;
  font-size: 2rem;
  color: #fff;
  opacity: 0.8;
  z-index: 1;
  background: none;
  border: none;
}

.popup-modal .close:hover {
  color: #fff;
  opacity: 1;
}

.popup-modal .modal-body img {
  width: 100%;
  height: auto;
}
.popup-modal .modal-content {
  background: none !important;
}
.popup-modal .handle-pre-banner-modal {
  /* margin: 10px; */
  width: 100%;
  height: 320px;
  border: 10px solid #fff;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}
.popup-modal .close-btn-modal {
  position: absolute;
  top: -10px;
  right: 6px;
  color: white;
  background: none !important;
  border: none !important;
  text-decoration: none;
  font-size: 50px;
}
