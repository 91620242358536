#home header {
  /* padding: 5px 0px; */
  position: fixed;
  z-index: 9999;
}

header .cheader {
  /* overflow-x: hidden; */
  background-color: #121212e3;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-right: 50px;
}
header .cheader .logo {
  width: 200px;
}
.container-fluidss2 {
  background: transparent;
  color: #fff;
  width: 100%;
}
.inactive-color-li {
  color: white !important;
}
.active-color-li {
  color: #fff;
}
.container-fluidss {
  /* background: white; */
  width: 100%;
}
@media (max-width: 1399.98px) {
  header .cheader .logo {
    width: 200px;
  }
}
@media (max-width: 1199.98px) {
  header .cheader .logo {
    width: 200px;
  }
  .location ul {
    padding-left: 0px;
    margin-left: 0px;
  }
}
@media (max-width: 575.98px) {
  header .cheader .logo {
    width: 125px;
  }
  .header .cheader {
    justify-content: start;
  }
}
header .cheader .navigation {
  margin-right: 20px;
}
/* @media (max-width: 1399.98px) {
  header .cheader .navigation {
    margin-right: -150px;
  }
} */
@media (max-width: 1199.98px) {
  header .cheader .navigation {
    margin-right: -0px;
  }
}
@media (max-width: 992.98px) {
  header .cheader .navigation {
    margin-right: 0px;
    margin-top: 0px;
    width: 75%;
    position: fixed;
    z-index: 99999 !important;
    top: 0;
    right: -200%;
    bottom: 0;
    /* right: 0; */
    padding-top: 10px;
    background-color: rgba(0, 0, 0, 1);
    transition: right 0.3s ease-in-out;
    overflow-y: scroll;
  }
  header .cheader .location {
    margin-right: 0px;
    margin-top: 0px;
    width: 75%;
    position: fixed;
    z-index: 999999 !important;
    bottom: 0;
    right: -200%;
    bottom: 7%;
    padding-top: 0px;
    display: none !important;
    /* right: 0; */
    /* background-color: #fff; */
    transition: right 0.3s ease-in-out;
  }
}
@media (max-width: 992.98px) {
  header .cheader .navigation {
    /* width: 100%; */
  }
}
header .cheader .navigation ul {
  margin-top: 28px;
  padding: 0;
  display: flex;
  align-items: center;
  gap: 5px;
}
header .cheader .navigation ul.dropdown-menu {
  margin: 0;
  padding: 0;
  display: flex;
  align-items: center;
}
@media (max-width: 992.98px) {
  header .cheader .navigation ul {
    flex-direction: column;
  }
}
header .cheader .navigation ul li {
  list-style: none;
  cursor: pointer;
  /* margin: 0px 15px; */
  padding: 10px 14px;
  /* &:last-child{
      color: $primary;
      text-transform: uppercase;
      padding: 7px 30px;
      border-radius: 50px;
      border: 2px solid $secondary;
      background-color: $secondary;
      @include btn-animation;
      a{
        color: $white;
      }
      &:hover{
        background-color: $primary;
        border: 2px solid $primary;
        a{
          color: $white;
          border: none;
        }
      }
      @media (max-width: 1199.98px) {
         padding: 7px 25px;
        }
      @media (max-width: 575.98px) {
          font-size: 15px;
          padding: 9px 25px;
      }
    } */
}
@media (max-width: 1399.98px) {
  header .cheader .navigation ul li {
    padding: 5px 5px;
  }
}
@media (max-width: 1199.98px) {
  header .cheader .navigation ul li {
    padding: 4px 10px;
  }
  header .cheader .location ul .getStarted {
    margin: 0px;
  }
  header .cheader .navigation {
    margin: 0px;
  }
}
@media (max-width: 992.98px) {
  header .cheader .navigation ul li {
    margin: 5px 0px;
  }
}
header .navigation ul .dropdown .dropdown-menu li {
  padding: 4px 0px;
}
header .cheader .navigation ul li a {
  /* text-decoration: none;
  font-size: 12px;
  text-transform: uppercase;

  padding-bottom: 10px;
  letter-spacing: 0.1em;
  color: #000;
  padding: 16px 14px;
  font-weight: 500;
  border-radius: 5px; */
  color: #fff;
  text-decoration: none;
  border-radius: 0px;
  font-size: 14px;
  font-weight: 500;
  cursor: pointer;

  box-shadow: inset 0 0 0 0 #d70757;
  /* -webkit-transition: ease-out 0.4s;
  -moz-transition: ease-out 0.4s; */
  /* transition: ease-out 0.2s; */
  /* transition: 0.2s; */
}

header .cheader .navigation ul li a:hover {
  /* box-shadow: inset 400px 0 0 0 #d70757; */
  /* background-color: #d70757; */
  /* color: #fff !important; */
  /* border-radius: 5px; */
  /* padding: 10px 16px; */
  /* padding: 10px 16px; */
  border-bottom: 2px solid #d70757;
}
/* header .cheader .navigation ul li:hover a {
  color: #fff;
} */
header .cheader .navigation ul li .dropdown-menu li:hover {
  color: #000 !important;

  /* padding: 10px 12px; */
}
/* header .cheader .navigation ul li a:hover {
  background-color: #d70d57;
  color: #fff;
  width: 100%;
} */
@media (max-width: 1399.98px) {
  header .cheader .navigation ul li a {
    font-size: 14.5px;
  }
}
@media (max-width: 1199.98px) {
  header .cheader .navigation ul li a {
    font-size: 14px;
  }

  .px-lg-5 {
    padding-right: 1.7rem !important;
    padding-right: 0.5rem !important;
  }
}
@media (max-width: 992.98px) {
  header .cheader .navigation ul li a {
    font-size: 12px;
    color: #ffffff;
    border-bottom: 2px solid transparent;
  }
  header .cheader .navigation ul li a:hover {
    color: #000;
  }
}
header .cheader .active {
  right: 0%;
}
header .cheader .hamburger {
  display: none;
  position: absolute;
  top: 25px;
  right: 0;
  /* width: 60px; */
  height: 60px;
  /* transition-duration: 0.5s; */
}
@media (max-width: 992.98px) {
  header .cheader .hamburger {
    position: relative;
    z-index: 99999999999 !important;
    display: block;
    margin-top: -20px;
  }
  header {
    padding: 13px;
  }
}
@media (max-width: 575.98px) {
  header .cheader .hamburger {
    width: 20px;
    transform: scale(0.7);
  }
  .container-fluidss2 {
    width: 97%;
  }
}
header .cheader .hamburger .icon {
  /* transition-duration: 0.5s; */
  position: absolute;
  height: 4px;
  width: 30px;
  top: 30px;
  right: 0px;
  background-color: #dc2751;
}
header .cheader .hamburger .icon:before {
  /* transition-duration: 0.5s; */
  position: absolute;
  width: 30px;
  height: 4px;
  background-color: #dc2751;
  content: "";
  top: -10px;
}
header .cheader .hamburger .icon:after {
  /* transition-duration: 0.5s; */
  position: absolute;
  width: 30px;
  height: 4px;
  background-color: #dc2751;
  content: "";
  top: 10px;
}
header .cheader .hamburger.open .icon {
  /* transition-duration: 0.5s; */
  background: transparent;
}
header .cheader .hamburger.open .icon:before {
  background-color: #dc2751 !important;
  transform: rotateZ(45deg) scaleX(1.25) translate(6.5px, 6.5px);
}
header .cheader .hamburger.open .icon:after {
  background-color: #dc2751 !important;
  transform: rotateZ(-45deg) scaleX(1.25) translate(6px, -6px);
}
header .cheader .hamburger:hover {
  cursor: pointer;
}

.active {
  right: 0% !important;
}
/* Dropdown menu styling */
header .navigation ul .dropdown .dropdown-menu {
  display: none;
  position: absolute;
  background-color: #fff;
  color: #000;
  width: 220px;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
  z-index: 2;
  top: 34px;
}

header .navigation ul .dropdown:hover .dropdown-menu {
  display: block;
  color: black;
  /* padding: 10px 0px; */
}

header .navigation ul .dropdown .dropdown-menu li {
  display: block;
  padding: 7px;
}

header .navigation ul .dropdown .dropdown-menu li a {
  padding: 8px 10px;
  white-space: nowrap;
}
header .navigation ul .dropdown .dropdown-menu li.active a {
  background-color: #d70d57;
  color: black;
}
.navbar-fonts {
  animation: jump 0.3s infinite;
  background: #fff;
  height: 48px;
  border-radius: 50%;
  width: 48px;
  color: #d70d57;
  padding: 0.6rem;
  position: absolute;
  top: -2px;
  left: -2px;
}
.phone-number-bttn {
  border-radius: 3px;
}
/* .phone-number-bttn:hover {
  background-color: transparent !important;
  color: #000 !important;
  border: 1px solid #d70d57;
}
.phone-number-bttn:hover a {
  color: #d70d57 !important;
} */
@media (max-width: 992.98px) {
  header .navigation ul .dropdown {
    text-align: center;
  }
  header .navigation ul .dropdown .dropdown-menu {
    position: relative;
    box-shadow: none;
    top: 15px;
  }
  header .navigation ul .dropdown .dropdown-menu li {
    text-align: center;
  }
  header .navigation ul .dropdown .dropdown-menu a {
    color: #000;
  }
  .menu.hamburger {
    display: block;
    cursor: pointer;
  }
}

/* header .cheader .navigation ul li:hover{
    background-color: #dc2751;
  } */
/* header .cheader .navigation ul li:hover a{
    color: #ffffff;
  } */
header .cheader .navigation ul li .dropdown-menu li a {
  color: #000;
}
header .cheader .navigation ul li .dropdown-menu li:hover {
  background-color: transparent !important;
  color: black;
}
header .cheader .navigation ul li .dropdown-menu li:hover a {
  /* color: #dc2751 !important; */
  color: #000;
}
.phone-number-fonts {
  font-size: 19px;
}
@media (max-width: 992.98px) {
  header .cheader .navigation ul li:hover {
    background-color: transparent;
  }
}
header .cheader .location ul {
  display: flex;
  align-items: center;
  margin-bottom: 0;
  list-style: none;
}
header .cheader .location ul .getStarted {
  /* color: #ffffff;
    text-transform: uppercase;
    padding: 7px 30px;
    border-radius: 50px;
    border: 2px solid #dc2751;
    background-color: #dc2751;
    transition: 0.5s all; */
  background: #d70d57;
  padding: 10px 10px;
  /* font-family: Varela Round; */
  font-size: 14px;
  text-decoration: none;
  color: #fff;
  margin-right: 7px;
  letter-spacing: 0.0725em;
  margin: auto;
  /* transition: all 0.4s; */
}
header .cheader .location ul .getStarted2 {
  width: 40px !important;
  height: 40px;
  padding: 0;
  line-height: 40px;
  text-align: center;
  margin: auto;
}
header .cheader .location ul .getStarted a {
  color: #ffffff;
  text-decoration: none;
  cursor: pointer;
}
/* header .cheader .location ul .getStarted:hover {
  background-color: transparent;
  border: 2px solid #dc2751;
} */
/* header .cheader .location ul .getStarted:hover a {
  color: #dc2751;
  border: none;
} */
@media (max-width: 1199.98px) {
  header .cheader .location ul .getStarted {
    padding: 7px 25px;
  }
  .navbar-fonts {
    margin-top: 0px;
    margin-right: 2px;
  }
}
@media (max-width: 992.98px) {
  header .cheader .location ul .getStarted2 {
    padding: 0;
  }
  header .cheader .location ul .getStarted {
    padding: 0;
    width: 40px !important;
    height: 40px;
    text-align: center;
    line-height: 40px;
  }
  header .cheader .location ul .getStarted a {
    text-align: center !important;
    justify-content: center;
  }
}
@media (max-width: 575.98px) {
  header .cheader .location ul .getStarted {
    font-size: 15px;
    padding: 0;
  }
  header .cheader .hamburger {
    /* right: 20px; */
    transform: scale(1.01);
  }
}

/* header .cheader .navigation ul li a:before {
    content: "";
    position: absolute;
    top: 73px;
    right: 50%;
    margin-right: -12px;
    height: 2px;
    width: 0px;
    background: #d70d57;
    border: none;
    transition: all 0.4s;
}
header .cheader .navigation ul li a:before {
    position: absolute;
    top: 42px;
    right: 27%;
    height: 2px;
    width: 43px;
    background: #fff;
    border: none;
} */
header .cheader .navigation ul li a.active {
  background-color: #d70d57;
  color: #fff;
}
.booking-Request-btn {
  background: #d70d57 !important;
  padding: 10px 10px;
  border: none !important;
  /* font-family: Varela Round; */
  font-size: 14px;
  text-decoration: none;
  color: #fff;
  margin-right: 7px;
  letter-spacing: 0.0725em;
}
.modal-header {
  /* background-color: #d70d57 !important; */
  color: white !important;
}
.nav-logo-style {
  margin-right: 4px;
  margin-top: -6px;
  color: #d70d57;
}

header .cheader .navigation ul li:hover .nav-logo-style {
  color: #fff;
}

@keyframes jump {
  0% {
    transform: rotate(0deg);
  }

  25% {
    transform: rotate(7deg);
  }
  50% {
    transform: rotate(0deg);
  }
  75% {
    transform: rotate(-7deg);
  }
  100% {
    transform: rotate(0deg);
  }
}

header .cheader .location ul .getStarted {
  background: #fff !important;
  border: 1px solid #d70d57;
  color: #fff;
  font-size: 14px;
  letter-spacing: 0.0725em;
  margin-right: 7px;
  padding: 7px 12px;
  border-radius: 50%;
  text-decoration: none;
}
/* header .cheader .location ul .getStarted:hover {
  background: #fff;
  border: 1px solid #d70d57;
  color: #d70d57;
} */
/* header .cheader .location ul .getStarted:hover a {
  color: #fff;
} */
.google-map-img {
  height: 25px;
  width: 25px;
}
header .cheader .location ul .getStarted.phone-number-bttn {
  background-color: #fff;
  border-radius: 0% !important;
  border: none;
}
header .cheader .location ul .getStarted.phone-number-bttn a {
  background-color: #d70d57;
  padding: 0.5rem 1rem 0.5rem 3rem;
  border-radius: 1.2rem;
  font-size: 0.9rem;
  position: relative;
}
@media (max-width: 575.99px) {
  .navbar-fonts {
    right: -10px;

    top: -10px;
    border: 1px solid #d70d57;
  }
  header .cheader .location ul .getStarted.phone-number-bttn a {
    padding: 0px;
    background: #fff;
  }
  header .cheader .location ul .getStarted {
    padding: 0px;
  }
  footer p.h6.strong {
    font-size: 13px !important;
  }
}
@media (max-width: 1000.98px) {
  header .cheader .location ul .getStarted a {
    padding: 0px !important;
    background-color: #fff !important;
  }
  .navbar-fonts {
    top: 0px;
    border: 1px solid #d70d57;
    right: 0px;
  }
  header .cheader .location ul .getStarted {
    padding: 0px !important;
  }
}
@media (max-width: 992.98px) {
  header .cheader .location ul {
    justify-content: center !important;
    display: flex !important;
    /* flex-direction: column !important; */
    align-items: center !important;
    text-align: center !important;
    margin: 0 auto !important;
    display: block !important;
  }
  header .cheader .location ul .getStarted {
    display: flex;
    align-items: center !important;
    justify-content: center;
    width: unset !important;
    background-color: transparent !important;
    border: none;
  }
  header .cheader .location ul .getStarted .txt {
    color: #000;
    padding: 0px 20px;
    padding-left: 50px !important;
  }
  .socialss {
    margin-top: 20px;
  }
  .socialss .hLuZHF .social_profiles .social_profile_item {
    width: 32px;
    height: 27px;
    background-color: #d70d57;
    text-align: center;
  }
  .location-maps {
    display: none !important;
  }
  .socialss .fvcUgI {
    margin: 0px 4px !important;
  }
}
@media (max-width: 426px) {
  .phone-number-fonts {
    font-size: 16px;
  }
}
.SocialShare__Wrapper-h9wts-0 {
  display: flex;
  justify-content: center;
  align-items: center;
}
@media (min-width: 992.98px) {
  .socialss {
    display: none;
  }
}
.service_text_clr {
  color: #fff !important;
}
@media (max-width: 992.98px) {
  .service_text_clr {
    color: #fff !important;
  }
}
@media (min-width: 992px) {
  .px-lg-5 {
    padding-left: 2rem !important;
    padding-right: 2rem !important;
  }
}
@media ((max-width: 1124px) and (min-width: 992px)) {
  .container-fluidss .px-lg-5 {
    padding-left: 1rem !important;
    padding-right: 1rem !important;
  }
  header .cheader .logo {
    width: 165px;
  }
  header .cheader .navigation ul li a {
    font-size: 14px;
  }
  .phone-number-fonts {
    font-size: 16px;
  }
}

.navbar_phone_number {
  display: none;
}

@media (max-width: 1024px) {
  header .cheader .location ul .getStarted.phone-number-bttn {
    margin-right: 0px !important;
  }
}

@media (max-width: 992.98px) {
  .navbar_phone_number {
    display: block;
    margin-top: 40px !important;
  }
}

.navbar_phone_number {
  position: relative;
}
.navbar_phone_number .phone-number-fonts {
  /* margin-left: 27px; */
  background-color: #d70757;
  border-radius: 50px;
  padding: 10px 16px 10px 48px;
}
.navbar_phone_number .navbar-fonts {
  animation: jump 0.3s infinite;
  background: #fff;
  height: 48px;
  border-radius: 50%;
  width: 48px;
  color: #d70d57;
  padding: 0.6rem;
  position: absolute;
  top: 4px !important;
  left: 7px !important;
}
header .cheader .navigation ul .navbar_phone_number a {
  padding: 0px !important;
}
@media (max-width: 575.98px) {
  header .cheader .navigation {
    width: 70%;
  }
  .navbar_phone_number .navbar-fonts {
    height: 36px;
    width: 36px;
  }
  .navbar_phone_number .phone-number-fonts {
    /* margin-left: 27px; */
    background-color: #d70757;
    border-radius: 50px;
    padding: 5px 16px 5px 35px;
  }
}

/* .tooltipss {
  position: relative;
  display: inline-block;
} */

/* .tooltipss:hover .tooltiptext {
  visibility: hidden;
}
.tooltipss .tooltiptext{
  visibility: hidden;
  background-color: #d70d57;
  color: #fff;
  width: 140px;
  text-align: center;
  padding: 5px 0px;
  border-radius: 6px;
  position: absolute;
  z-index: 1;
  bottom: 150%;
  left: 50%;
  margin-left: -70px;
  opacity: 0;
  transition: opacity 1s;
}
.tooltipss .tooltiptext::before{
content: "";
border: 5px solid ;
border-color: #d70757 transparent transparent transparent;
position: absolute;
top: 100%;
left: 50%;
margin-left: -5px;
} */

.tooltipss {
  position: relative;
  display: inline-block;
  /* Optional: Customize tooltip background color, text color, etc. */
}

/* Tooltip text */
.tooltipss .tooltiptext {
  visibility: hidden;
  background-color: #d70d57;
  color: #fff;
  width: 95px;
  text-align: center;
  padding: 4px 0px;
  border-radius: 6px;
  position: absolute;
  z-index: 1;
  top: 136%;
  left: 125%;
  margin-left: -70px;
  font-size: 14px;
  opacity: 0;
  transition: opacity 1s;
  /* visibility: hidden;
  width: 85px;
  background-color: #d70d57;
  color: white;
  text-align: center;
  border-radius: 6px;
  padding: 4px 0;

 
  position: absolute;
  z-index: 1;
  top: 130%;
  left: -23%;

font-size: 14px;
  opacity: 0;
  transition: opacity 0.3s; */
}

/* Show the tooltip text when hovering over the tooltip container */
.tooltipss:hover .tooltiptext {
  visibility: visible;
  opacity: 1;
}
.tooltipss .tooltiptext::before {
  content: "";
  border: 5px solid;
  border-color: transparent transparent #d70757 transparent;
  position: absolute;
  top: -34%;
  left: 56%;
  margin-left: -5px;
}

.navbar-fontsss {
  animation: jump 0.3s infinite;
  /* background: #fff; */
  border: 1px solid #d70d57;
  border-radius: 50%;
  color: #d70d57;
  height: 35px;

  padding: 0.6rem;
  display: none;
  width: 35px;
}
.location-mapsss {
  display: none;

  background: #fff !important;
  border: 1px solid #d70d57;
  border-radius: 50%;
  color: #fff;
  font-size: 14px;
  letter-spacing: 0.0725em;
  margin-right: 7px;
  padding: 6px 6px;
  text-decoration: none;
}
.tooltipsss .tooltiptext {
  visibility: hidden;
  background-color: #d70d57;
  color: #fff;
  width: 95px;
  text-align: center;
  padding: 4px 0px;
  border-radius: 6px;
  position: absolute;
  z-index: 1;
  top: 118%;
  left: 125%;
  margin-left: -70px;
  font-size: 14px;
  opacity: 0;
  transition: opacity 1s;
}
.tooltipsss .tooltiptext::before {
  content: "";
  border: 5px solid;
  border-color: transparent transparent #d70757 transparent;
  position: absolute;
  top: -34%;
  left: 42%;
  margin-left: -5px;
}

.tooltipsss {
  position: relative;
  display: inline-block;
  /* Optional: Customize tooltip background color, text color, etc. */
}

/* Show the tooltip text when hovering over the tooltip container */
.tooltipsss:hover .tooltiptext {
  visibility: visible;
  opacity: 1;
}
.google-map-imggg {
  width: 20px;
  height: 20px;
}
@media (max-width: 992.98px) {
  .location-mapsss {
    display: block;
  }
  .navbar-fontsss {
    display: block;
  }
}

.hheaader {
  display: flex;
  gap: 20px;
}

.container-fluidss .button_button__MFmXH,
.container-fluidss .button_button__MFmXH:active,
.container-fluidss .button_button__MFmXH:link,
.container-fluidss .button_button__MFmXH:visited {
  font-size: 15px;
  font-weight: 600;
  border-radius: 5px;
}

.button_color-login {
  color: #000;
  border: 1px double transparent;
  background-image: linear-gradient(rgb(255 255 255), rgb(255 255 255)),
    radial-gradient(circle at left top, rgb(1, 110, 218), rgb(217, 0, 192));
  background-origin: border-box;
  background-clip: padding-box, border-box;
}
header {
  transition: top 0.3s;
}

header.hide {
  top: -100px; /* Adjust as needed */
}

header.show {
  top: 40px !important;
}

header.sticky.show {
  top: 0 !important; /* When the user scrolls and the navbar is sticky */
}

@media (max-width: 768px) {
  /* Adjust breakpoint as needed */
  header {
    position: fixed;
    width: 100%;
    top: -13px !important;
    left: 0;
    z-index: 1000; /* Ensure it stays above other content */
  }
}

@media (max-width: 992.9px) {
  .container-fluidss .button_button__MFmXH {
    position: relative;
    padding: 7px 10px;
    font-size: 12px !important;
    border-radius: 10px;
    font-weight: 900;
    letter-spacing: 1px;
    text-transform: uppercase;
    color: #000;
    transition: all 50ms ease;
    text-decoration: none;
    width: fit-content;
    white-space: nowrap;
    align-items: center;
    z-index: 1;
    outline: 0;
    cursor: pointer;
    display: flex;
    gap: 5px;
    /* justify-content: center; */
  }
}
.header_skew {
  background-color: #ffffff;
  padding-left: 40px;
  padding: 5px 20px 5px 50px;
  margin-left: -30px;
  transform: skew(-22deg);
}

.header_skew .logo {
  transform: skew(22deg);
}
@media (max-width: 575.98px) {
  .header_skew {
    background-color: #ffffff;
    padding-left: 40px;
    padding: 5px 10px 5px 30px;
    margin-left: -30px;
    transform: skew(-22deg);
  }
  header .cheader .hamburger {
    right: -28px;
  }
  .spaceetc {
    /* margin-right: -50px; */
    gap: 10px !important;
  }
  .banner-offer-cont {
    padding: 2px 0px;
  }
  header .cheader {
    padding-right: 20px;
  }
}

/* .menu_btn {
  font-family: inherit;
  font-feature-settings: inherit;
  font-variation-settings: inherit;
  font-size: 100%;
  font-weight: inherit;
  line-height: inherit;
  letter-spacing: inherit;
  color: inherit;
  margin: 0;
  padding: 0;
  -webkit-appearance: button;
  background-color: transparent;
  font-size: 0.75rem;
  line-height: 1rem;
  font-weight: 700;
  text-transform: uppercase;
  letter-spacing: 0.05em;
  position: relative;
  margin-left: auto;
  display: inline-flex;
  height: 2.75rem;
  align-items: center;
  justify-content: center;
  padding-left: 0.75rem;
  padding-right: 0.75rem;
  transition-property: color, background-color, border-color,
    text-decoration-color, fill, stroke;
  transition-timing-function: linear;
  transition-duration: 0.2s;

  --tw-text-opacity: 1;
  color: rgb(172 106 255 / 1);
} */
/* .custom-btn {
  background-color: #007bff;
  border: none;
  position: relative;
  overflow: hidden;
  padding: 10px 20px;
} */

/* .custom-btn:hover {
  background-color: #0056b3;
} */

/* .menu_btn svg {
  pointer-events: none;
} */

/* .relative {
  position: relative;
}
.z-10 {
  z-index: 10;
}
.absolute {
  position: absolute;
}
.left-0 {
  left: 0;
}
.top-0 {
  top: 0;
} */