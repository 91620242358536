.block {
  padding-top: 70px;
  padding-bottom: 130px;
  position: relative;
  background-color: #000;
}
body.is-loaded .page-main .block:not(.block--title):not(.block--slider) {
  opacity: 1;
}
.vc_column-inner::after,
.vc_column-inner::before {
  content: " ";
  display: table;
}
.text-center {
  text-align: center;
}
.title-wrap:not(:last-child) {
  margin-bottom: 60px;
}
.shortcode_vc_column_inner .heading {
  font-size: 60px;
  line-height: 50px;
  letter-spacing: -0.03em;
  color: #fff;
  font-weight: 800;
  margin-bottom: 16px;
}
.title-wrap h2:first-child {
  margin-top: 0;
}
.p--lg {
  font-size: 17px;
  line-height: 1.6;
}
h2.heading + .p--lg {
  padding-top: 11px;
  color: #fff;
}
.price-box {
  text-align: center;
  margin-top: 76px;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  padding-top: 60px;
  /* height: 540px; */
  border: 1px solid #4b4b4b;
  margin-bottom: 10px;
  border-radius: 10px;
}
.price-box-price-bg01 {
  /* background-image: url("https://img.freepik.com/premium-photo/music-abstract-background-black-background_566493-4451.jpg"); */
  background-size: contain;
  background-color: #000;
}
.price-box-icon {
  margin: -100px auto 5px;
  width: 100px;
  height: 100px;
  border-radius: 50%;
  text-align: center;
  display: flex;
  background: #ffffff;
  box-sizing: border-box;
  box-shadow: 0px 0px 20px rgba(52, 51, 55, 0.1);
}
svg:not(:root) {
  overflow: hidden;
}
.price-box-icon .icon {
  display: block;
  color: #13161d;
  text-align: center;
  width: 35px;
  height: 41px;
  vertical-align: middle;
  margin: auto;
}
@media (max-width: 1679px) {
  .price-box-icon .icon {
    width: 35px;
    height: 41px;
    line-height: 116px;
  }
}
.price-box-icon .icon.icon-recording-rates {
  width: 21px;
  height: 37px;
}
.price-box-price {
  font-weight: bold;
  font-size: 18px;
  line-height: 19px;
  letter-spacing: -0.03em;
  color: #fff;
  position: relative;
  padding-bottom: 11px;
}

.price-box-price:before {
  content: "";
  position: absolute;
  z-index: 1;
  bottom: 0;
  left: 0;
  right: 0;
  height: 4px;
  background-size: 100% 100%;
}
.form-popup::after,
.price-box-price:before {
  transform: rotate(-180deg);
}
.price-box-price:before {
  background: linear-gradient(270deg, #f56f6f 0%, #000000 100%);
}
.price-box-price .price-symbol {
  color: #ff6600;
}
.price-box-price span {
  vertical-align: middle;
}
.price-box-price b {
  font-size: 30px;
  text-decoration: line-through;
  line-height: 71px;
  letter-spacing: -0.03em;
}
/* .price-box-price b {
  color: #ff6600;
} */
.price-box-title {
  position: relative;
  background-color:#1a1a1a;

  font-size: 22px;
  line-height: 37px;
  color: #3d3b42;
  font-size: 24px;
  line-height: 25px;
  letter-spacing: -0.03em;
  font-weight: bold;
  padding-top: 46px;
}
.price-box-title span {
  color: #fff;
}
.price-box-list {
  padding: 0 50px;
  list-style: none;
  font-size: 17px;
  line-height: 38px;
  background-color: #1a1a1a;
  color: #cfcfcf;
  padding-bottom: 30px;
  margin-bottom: 0;
}
.price-box-list:not(:first-child) {
  padding-top: 14px;
}
.price-box-list li {
  margin-bottom: 0;
  padding-top: 5px;
  padding-bottom: 5px;
  font-size: 17px;
  line-height: 1.6em;
  position: relative;
  list-style: none;
}
.price-box-list li {
  padding-left: 0;
}
.price-box-list li:not(:first-child) {
  border-top: 2px solid rgba(211, 211, 213, 0.3);
}
ul li:last-child {
  margin-bottom: 0;
}
.price-box-btn {
  padding-bottom: 40px;
}
.btn--border1 {
  background: linear-gradient(270deg, #ff6600 0%, #7b16d9 100%);
}
.btnmn {
  color: #3d3b42;
  position: relative;
  padding: 19px 45px 20px 45px;
  box-sizing: border-box;
  background-clip: padding-box;
  border: none;
  border-radius: 40px;
  overflow: visible;
  font-size: 16px;
  height: auto;
  font-weight: bold;
  text-transform: none;
  letter-spacing: -0.01em;
  text-decoration: none;
  transition: background 0.3s ease-out, color 0.3s;
}
.btn--border1,
.wp-block-button__link {
  position: relative;
  color: #fff;
  background-clip: padding-box;
  overflow: visible;
  z-index: 1;
}
.btn--border1:before {
  /* content: ""; */
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: -1;
  margin: 2px;
  border-radius: inherit;
  background: #fff;
}
@media (min-width: 768px) {
  .price-box--special {
    margin-top: 1px;
  }
}
.price-box--special .price-box-icon {
    background-image: linear-gradient(-45deg, #00ef86, #008cff, #ec3258, #fcda00);
}
@media (min-width: 768px) {
  .price-box-btn {
    padding-top: 30px;
  }
}

.overlay {
  background: #06060661;
  height: 100%;
  left: 0;
  opacity: 0.4;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 2;
}
.overlay-bold {
  opacity: 0.6;
}

.pricing-table-positioning {
  position: relative;
  z-index: 10;
}
@media (max-width:768px) {
  .price-box{
    margin-top: 10px;
  }
  .tt-shortcode_vc_row{
    padding-top: 50px;
  }
  .p--lg{
    font-size: 15px;
    padding-top: 0px;
    margin-bottom: 0px;
  }
  .price-box{
    height: max-content !important;
  }
}
.price-box-price .tttt {
  font-size: 45px;
}
/* .first-price-btnn{
  padding-top: 80px;
} */
@media (max-width:768px) {
  .first-price-btnn{
    padding-top: 40px;
  }
}

@media (max-width:448px){
  .price-box-list{
    padding-inline: 10px;
  }

  .price-box{
    margin-block: 30px;
  }
}