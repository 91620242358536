.dark-theme-container {
  background-color: #000;
  color: #fff;
  min-height: 100vh;
  padding: 50px 0;
}

.dark-theme-card {
  background-color: #18042b !important;
  border: none;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.5);
  color: #fff !important;
}

.dark-theme-card .form-control {
  background-color: #000;
  border: 1px solid #18042b;
  color: #fff;
  padding: 10px 20px;
  margin: 5px 0px 10px 0px;
}

.dark-theme-card .form-control:focus{
  color: #ffffffa8;
}

.dark-theme-card .form-control:focus {
  background-color: #000;
  border-color: #a778d3;
  box-shadow: none;
}

.dark-theme-card .form-check-input {
  background-color: #000;
  border-color: #ca8dc4;
}

.dark -theme-card .form-check-label {
  color: #ccc;
}

.dark-theme-card .btn-primary {
  background-color: violet;
  border: none;
  width: 100%;
  padding: 10px 20px;
  margin-top: 20px;
}

.dark-theme-card .btn-primary {
  background-color: violet;
}


.success-modal .modal-body {
  background-color: #f9f9f9;
  border-top: 5px solid #28a745;
  border-radius: 8px;
  padding: 20px;
}

.success-modal .success-icon {
  font-size: 60px;
  color: #28a745;
}

.success-modal h4 {
  font-weight: bold;
  color: #333;
}

.success-modal p {
  color: #555;
}

.success-modal .btn-success {
  background-color: #28a745;
  border-color: #28a745;
  font-weight: bold;
}

.success-modal .btn-success:hover {
  background-color: #218838;
  border-color: #1e7e34;
}
