.price-container-one {
  display: flex;
  gap: 20px;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
}
/* .price-one-heads{
    width: 50%;
}
.price-one-heads{
    width: 50%;
} */
.price-one-heads-paras {
  color: #b9b9b9;
  font-style: normal;
  font-weight: 500;
  letter-spacing: -0.1px;
  line-height: 27px;
  text-decoration: none;
  text-transform: none;
}
.price-one-heads h1 {
  color: #fff;
  font-style: normal;
  font-weight: 700;
  text-decoration: none;
  text-transform: none;
}
.price-one-heads-para {
  margin-bottom: 4px;
  color: "#F4263E";
  font-size: 20px;
  font-weight: 700;
}
.cards__card {
  background-color: #fff;
  border: 1px solid #f4f4f9;
  border-radius: 36px;
  -webkit-filter: drop-shadow(0 3px 10px rgba(31, 37, 89, 0.04));
  filter: drop-shadow(0 3px 10px rgba(31, 37, 89, 0.04));
  overflow: hidden;
  transition: 0.2s ease-in-out;
}
.elements--pricing__card_section-2-module-1 {
  padding-top: 30px;
  padding-bottom: 25px;
  padding-left: 24px;
  padding-right: 19px;
  width: 100%;
  border-radius: 32px;
  /* height: 770px; */
  margin-block: 10px;
}
/* .elements--pricing__badge-default_section-2-module-1 {
    padding: 6px 19px;
    border-radius: 5px;
    text-transform: lowercase;
    background-color: #F4F4F9;
    color: #1F2559;
} */
.elements--pricing__price-value_section-2-module-1 {
  font-weight: bold;
  font-size: 24px;
  color: #dfdfdf;
}
.elements--pricing__description_section-2-module-1 {
  margin-top: 8px;
}
.elements--pricing__description_section-2-module-1 p {
  color: #9f9fa5;
  font-style: normal;
  font-weight: 400;
  letter-spacing: -0.1px;
  line-height: 27px;
  text-decoration: none;
  margin-bottom: 0px !important
    ;
  text-transform: none;
}
.elements--pricing__separator_section-2-module-1 {
  border-top: 1px solid #e7e8f1;
  width: 100%;
  margin: 10px 0 8px;
}
.elements--pricing__features-title_section-2-module-1 {
  font-weight: bold;
  margin-bottom: 32px;
  color: #d2d7ff;
  font-style: normal;
  font-weight: 500;
  letter-spacing: 0;
  text-decoration: none;
  text-transform: none;
}
.elements--pricing__features-container_section-2-module-1 {
  display: flex;
  margin: 10px 0;
}
.elements--pricing__features-image_section-2-module-1 {
  margin-right: 12px;
}
.elements--pricing__description_section-2-module-1 a:focus,
.elements--pricing__description_section-2-module-1 a:hover {
  color: #f4263e;
}
.elements--pricing__description_section-2-module-1 a {
  color: #62668a;
  font-style: normal;
  font-weight: 400;
  letter-spacing: -0.1px;
  line-height: 27px;
  text-decoration: none;
  text-transform: none;
  transition: 0.3s ease-in-out;
}
.elements--pricing__button-container_section-2-module-1 {
  margin-top: 24px;
}
.btn-secondary-white {
  background-color: #f4263e;
  border: 1px solid #f4263e;
  border-radius: 6px;
  color: #fff;
  cursor: pointer;
  display: inline-block;
  font-size: 1.2rem;
  font-weight: 700;
  height: auto;
  line-height: 1.1;
  margin: 0;
  padding: 20px 28px;
  position: relative;
  text-align: center;
  text-decoration: none;
  transition: 0.2s ease-in-out;
  white-space: normal;
  width: auto;
  word-break: break-word;
  background-color: #fff;
  border: 1px solid #bdbfd1;
  border-radius: 12px;
  color: #1f2559;
  -webkit-filter: drop-shadow(0 2px 6px rgba(31, 37, 89, 0.16));
  filter: drop-shadow(0 2px 6px rgba(31, 37, 89, 0.16));
  padding: 20px 28px;
  transition: 0.2s ease-in-out;
}
.elements--pricing__button-default_section-2-module-1 {
  width: 100%;
  box-shadow: none;
}
.elements--pricing__card-featured_section-2-module-1 {
  border: 2px solid #f4263e;
}
/* @media (min-width: 767px) {
    .elements--pricing__card-default_section-2-module-1 {
        margin-top: 40px;
    }
} */
.cards__card:hover {
  transform: scale(1.03) !important;
}

.cards__card {
  color: #fff;
  border: 3px double #fff;
  background: #000;
  /* background-image: linear-gradient(rgb(255 255 255), rgb(135 75 135 / 34%)), radial-gradient(circle at left top, rgb(237 237 237 / 12%), rgb(255 255 255)); */
  /* background-image: linear-gradient(rgb(255 255 255), rgb(87 19 50 / 71%)), radial-gradient(circle at left top, rgb(169 27 27 / 12%), rgb(41 41 41)); */
  background-origin: border-box;
  background-clip: padding-box, border-box;
}

.dIVOxo.dIVOxo {
  flex-direction: column;
  -webkit-box-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  align-items: center;
  padding: 16px;
  margin-top: 0px;
}

.ooTXc {
  display: flex;
}
.cKmRoT {
  display: inline-flex;
  flex-flow: row;
  -webkit-box-pack: center;
  place-content: center;
  -webkit-box-align: center;
  align-items: center;
  min-width: auto;
  text-align: center;
  white-space: nowrap;
  user-select: none;
  font-weight: 700;
  border-radius: 100rem;
  cursor: inherit;
  color: #fff;
  background-color: rgba(238, 36, 0, 0.24);
  text-transform: uppercase;
  height: 20px;
}
.dpJCJH {
  display: inline-flex;
  flex-flow: row;
  -webkit-box-pack: center;
  place-content: center;
  -webkit-box-align: center;
  align-items: center;
  padding: 0px 8px;
  font-size: 0.6875rem;
  line-height: 16px;
}
.fyFxgP.fyFxgP {
  width: 16px;
  height: 16px;
}

/* Price upper   */
#joinfree {
  padding-top: 120px;
  background: -webkit-gradient(
      linear,
      left top,
      left bottom,
      from(rgba(34, 34, 38, 0.502)),
      to(#222226)
    ),
    url("https://www.musicgateway.com/marketing-website/dist/img/pages/pricing/pricing-bg-alt.png")
      #363636 center top no-repeat;
  background: linear-gradient(180deg, rgba(0, 0, 0, 0.502) 0, #000 100%),
    url("https://www.musicgateway.com/marketing-website/dist/img/pages/pricing/pricing-bg-alt.png")
      #363636 center top no-repeat;
  width: 100%;
  background-size: cover;
}
.price_parent .section {
  padding: 3rem 1.5rem;
}
.pricing-page .options {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  font-size: 14px;
  color: #fff;
  margin: 0 60px 60px 60px;
}
.pricing-page .options .currency,
.pricing-page .options .term {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
}
.pricing-page .options .opt {
  background: #ffffff1a 0 0 no-repeat padding-box;
  padding: 10px 20px;
  margin: 0.5px;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}
.pricing-page .options .opt.l {
  border-radius: 100px 0 0 100px;
}
.pricing-page .options .opt.selected {
  background: #ffffff40 0 0 no-repeat padding-box;
}
#joinfree .heading {
  font-size: 60px;
  line-height: 84px;
  text-align: center;
}
.pricing-page #joinfree .heading {
  font-size: 40px;
  line-height: 84px;
  text-align: center;
  color: #fff;
  font-weight: 400;
  padding: 0 60px;
}
.has-text-white strong {
  color: #fff;
}
.pricing-page #joinfree .heading strong {
  font-weight: 800;
}
.pricing-page #joinfree .heading + * {
  margin-top: 30px;
}
.pricing-page #joinfree .plans-include {
  text-align: center;
}
.pricing-page #joinfree .plans-include .product-wrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  padding: 60px 0 0 0;
  font-weight: 800;
  line-height: 20px;
}
.pricing-page #joinfree .plans-include .product-wrapper .product {
  margin: 0 30px 30px 30px;
  max-width: 120px;
  width: 130px;
  text-align: center;
}
.pricing-page
  #joinfree
  .plans-include
  .product-wrapper
  .product
  .product-inner {
  background: #ffffff1a 0 0 no-repeat padding-box;
  border-radius: 10px;
  opacity: 1;
  backdrop-filter: blur(6px);
  -webkit-backdrop-filter: blur(6px);
  width: 130px;
  height: 130px;
  max-width: 130px;
  max-height: 130px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.pricing-page
  #joinfree
  .plans-include
  .product-wrapper
  .product
  .product-inner
  .icon {
  height: 40px;
}
.pricing-page #joinfree .plans-include .product-wrapper .product .product-name {
  margin-top: 20px;
  color: #fff;
}
.pricing-page
  #joinfree
  .plans-include
  .product-wrapper
  .product
  .product-inner
  .product-name {
  display: none;
}
.pricing_planToggle__bVkFD {
  border: 2px solid hsla(0, 0%, 100%, 0.2);
  display: grid;
  grid-template-columns: 1fr 1fr;

  width: -moz-fit-content;
  width: fit-content;
  font-size: 12px;
  border-radius: 100px;
  font-weight: 900;
  letter-spacing: 1px;
  background: transparent;
  text-transform: uppercase;
  color: #fff;
  transition: all 50ms ease;
  position: relative;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
}
.pricing_planToggle__bVkFf_tableee {
  content: "";
  display: block;
  height: 100%;
  /* width: 50%; */
  /* position: absolute;
    top: 0;
    left: 0; */
  border-radius: 100px;
  transition: all 0.2s ease-in-out;
  background-image: linear-gradient(-45deg, #00ef86, #008cff, #ec3258, #fcda00);
  animation: pricing_gradient__0zEKP 15s ease infinite;
  background-size: 400% 400%;
}
.pricing_toggleOption__EOUHy {
  border: none;
  padding: 13px 20px;
  text-decoration: none;
  white-space: nowrap;
  z-index: 1;
  text-align: center;
}
/* .pricing_planToggle__bVkFD.pricing_checked__TfOAP:before {
    left: 50%;
} */
.lements--pricing__pricees {
  text-decoration: line-through;
}
.elements--pricing__price-frecuency_section-2-module-1 {
  font-size: 45px;
  font-weight: 700;
  color: #fff;
}
.cardss_cardss {
  height: 620px;
}
.cards__cardss {
  height: 650px;
}

@media (max-width: 768px) {
  #joinfree {
    padding-top: 75px;
    padding-inline: 0.5rem;
  }

  .pricing-page #joinfree .heading {
    padding-inline: 0px;
    line-height: unset;
    font-size: 22px;
  }

  /* .pricing-page #joinfree .plans-include .product-wrapper {
    padding-top: 10px;
  } */
}

@media only screen and (max-width: 768px) {
  .pricing-page #joinfree .plans-include .product-wrapper {
    padding: 60px 0 20px 0;
  }

  .pricing-page #joinfree .plans-include .product-wrapper .product {
    width: 100%;
    max-width: 100%;
    margin: 0 0 10px 0;
  }

  .pricing-page
    #joinfree
    .plans-include
    .product-wrapper
    .product
    .product-inner {
    width: 100%;
    max-width: 100%;
    height: auto;
    max-height: auto;
  }

  .pricing-page
    #joinfree
    .plans-include
    .product-wrapper
    .product
    .product-inner
    .icon {
    padding: 0 20px;
    height: 30px;
  }

  .pricing-page
    #joinfree
    .plans-include
    .product-wrapper
    .product
    .product-inner
    .product-name {
    display: block;
    width: 100%;
    height: 40px;
    font-weight: 400;
  }

  .pricing-page
    #joinfree
    .plans-include
    .product-wrapper
    .product
    .product-name {
    display: none;
  }
}

.pricing_plan {
  padding: 50px 0px;
  background: #000;
}

@media (max-width: 448px) {
  .pricing_plan {
    padding: 10px 0px;
  }

  .elements--pricing__price-value_section-2-module-1 {
    font-size: 16px;
  }

  .elements--pricing__price-frecuency_section-2-module-1 {
    font-size: 28px;
  }

  .elements--pricing__button-default_section-2-module-1{
    padding: 10px 18px;
  }
}
@media (max-width:768px) {
  .price-container-one {
    justify-content: center;
}
  
}