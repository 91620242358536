/* Streams.css */
#streams.section {
    /* Assuming applyCardStyles applies some reusable card styles */
    color: white;
  }
  
  #streams .title-container {
    display: flex;
    justify-content: space-between;
  }
  
  #streams .title-container .title h1 {
    font-size: 2rem;
    letter-spacing: 0.2rem;
  }
  
  #streams .title-container .slider .services {
    display: flex;
    /* gap: 1rem; */
  }
  
  #streams .title-container .slider .services .service {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 0.6rem;
    min-width: 5rem;
  }
  
  #streams .title-container .slider .services .service img {
    height: 2rem;
  }
  
  #streams .title-container .slider .services .service h6 {
    font-size: 0.7rem;
  }
  
  #streams .chart {
    height: 300px;
  }
  
  #streams .chart .recharts-default-tooltip {
    background-color: var(--dark-background-color) !important;
    border: none !important;
    border-radius: 1rem;
    box-shadow: 0 0 60px 8px var(--primary-color);
  }
  
  @media screen and (min-width: 280px) and (max-width: 1080px) {
    #streams.section {
      height: 100%;
    }
  
    #streams .title-container {
      flex-direction: column;
      gap: 0.5rem;
    }
  
    #streams .title-container .title {
      text-align: center;
    }
  
    #streams .title-container .slider .services {
      display: grid;
      grid-template-columns: 1fr 1fr;
    }
  
    #streams .title-container .slider .services .service {
      gap: 0.5rem;
      min-width: 1rem;
    }
  }
  