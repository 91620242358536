.section_section__I0u9H {
  padding: 50px 20px;
  background-size: cover;
  position: relative;
  align-items: center;
  display: flex;
}
.section_section__I0u9H.section_header__XvyD1 {
  background: linear-gradient(transparent, #111),
    center 100%
      radial-gradient(ellipse at bottom, hsla(0, 0%, 8%, 0.8) 10% 10%, #141414),
    50% / 400% no-repeat
      linear-gradient(-45deg, #00ef86, #008cff, #ec3258, #fcda00);
  animation: section_gradient__n5Qe_ 15s ease infinite;
}
@media screen and (min-width: 768px) {
  .section_alignCenter__x2Hse {
    text-align: center;
  }
}
@media screen and (min-width: 768px) {
  .section_filmGrain__cakaz:after,
  .section_header__XvyD1:after {
    display: block;
    content: " ";
    position: absolute;
    inset: 0;
    mix-blend-mode: lighten;
    pointer-events: none;
    background-image: url(https://www.tunecore.com/images/dirt.gif);
  }
}
@media screen and (max-width: 768px) {
  .section_section__I0u9H {
    padding: 40px 20px;
  }
}
.about_us_page {
  padding-block: 150px;
}
.about_us_page h1{
    font-size: 5rem;
}
@media (max-width: 768px) {
  .about_us_page {
    padding-block: 75px;
  }
  .about_us_page h1{
    font-size: 2rem;
}
.about_us_page p{
    font-size: smaller;
}
}
