.padding-section-medium {
  padding-top: 1rem;
  padding-bottom: 30px;
}
.margin-xsmall {
  margin: 0.5rem;
}
.tag_component {
  color: rgba(255, 255, 255, 0.9);
  background-color: rgba(25, 17, 51, 0.5);
  border: 1px solid rgba(248, 246, 254, 0.2);
  border-radius: 99px;
  padding: 0.3rem 1.2rem;
  display: inline-block;
}
.tag_component.is-icon {
  grid-column-gap: 0.5rem;
  grid-row-gap: 0.5rem;
  justify-content: center;
  align-items: center;
  display: inline-flex;
}
#w-node-_839595b8-d3cf-4c20-3461-9de931b2e368-09d0b1db,
#w-node-_822acfd4-cd8c-5996-f34b-cf01f7beac12-09d0b1db,
#w-node-_40242f8b-ca6f-4963-63fd-daf04d813865-09d0b1db {
  grid-area: span 1 / span 1 / span 1 / span 1;
}
.w-embed {
  justify-content: center;
  align-items: flex-start;
  display: flex;
}
.w-embed:before,
.w-embed:after {
  content: " ";
  grid-area: 1 / 1 / 2 / 2;
  display: table;
}
.w-embed:after {
  clear: both;
}
.max-width-large {
  width: 100%;
  max-width: 48rem;
}
.heading-right-choice {
  color: rgba(255, 255, 255, 0.9);
  margin-top: 0;
  margin-bottom: 0;
  font-size: 50px;
  font-weight: 700;
  line-height: 1.2;
}
.w-layout-grid {
  grid-row-gap: 16px;
  grid-column-gap: 16px;
  grid-template-rows: auto auto;
  grid-template-columns: 1fr 1fr;
  grid-auto-columns: 1fr;
  display: grid;
}
.layout-2col_component {
  grid-column-gap: 0rem;
  grid-row-gap: 4rem;
  grid-template-rows: auto;
  grid-template-columns: 1fr 1fr;
  grid-auto-columns: 1fr;
  align-items: center;
  display: grid;
}
.home-data_grid {
  grid-column-gap: 4rem;
  grid-row-gap: 4rem;
  grid-template-rows: auto;
  grid-template-columns: 1fr 1fr;
  grid-auto-columns: 1fr;
  align-items: center;
  justify-items: stretch;
  display: grid;
}
.home-events_content {
  grid-column-gap: 3rem;
  grid-row-gap: 1.5rem;
  flex-flow: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  height: 600px;
  overflow-y: scroll;
  /* max-width: 28.5rem; */
  display: flex;
}
.home-events_content.is-data {
  max-width: 32rem;
  margin-top: 3rem;
}
.home-experts_grid-item {
  grid-column-gap: 0.75rem;
  grid-row-gap: 0.75rem;
  color: rgba(255, 255, 255, 0.9);
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 0.75rem;
  font-weight: 500;
  display: flex;
}
.home-experts_grid-number {
  background-color: #101010;
  color: rgba(255, 255, 255, 0.9);
  border: 1px solid rgba(248, 246, 254, 0.2);
  border-radius: 6px;
  justify-content: center;
  align-items: center;
  width: 2rem;
  height: 2rem;
  font-size: 0.875rem;
  line-height: 100%;
  display: flex;
}
.right-choice-para {
  color: #fff;
  margin-bottom: 0;

  font-size: 1rem;
  font-weight: 400;
}
.text-color-white {
  color: #fff;
}
.home-data_card {
  grid-column-gap: 1rem;
  grid-row-gap: 1rem;
  color: var(--base-color-brand--text_heading);
  background-color: rgba(25, 17, 51, 0.2);
  border: 1px solid rgba(141, 49, 245, 0.2);
  border-radius: 1.5rem;
  flex-flow: column;
  justify-content: center;
  align-items: center;
  min-height: 10rem;
  padding-top: 2rem;
  display: flex;
  position: relative;
}
.layout65_item-list {
  grid-column-gap: 1rem;
  grid-row-gap: 1rem;
  grid-template-rows: auto;
  grid-template-columns: 1fr;
  grid-auto-columns: 1fr;
  padding: 3rem 2rem;
  display: grid;
}
.layout65_item {
  display: flex;
}
.layout65_item-icon-wrapper {
  flex: none;
  align-self: flex-start;
  margin-right: 0.75rem;
}
.icon-embed-xsmall {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 1.25rem;
  height: 1.25rem;
  display: flex;
}
.w-embed:before,
.w-embed:after {
  content: " ";
  grid-area: 1 / 1 / 2 / 2;
  display: table;
}
.logo-looping-wrapper {
  height: 100%;
  position: relative;
}
.flex-horizontal {
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
  display: flex;
}
.marquee {
  flex-direction: row;
  flex: 1;
  justify-content: center;
  align-items: flex-start;
  width: 100%;
  height: 700px;
  display: flex;
  position: relative;
  overflow: hidden;
}
.marquee-cover {
  z-index: 10;
  position: absolute;
  top: 0%;
  bottom: 0%;
  left: 0%;
  right: 0%;
}
.track-vertical-alt {
  flex-direction: column;
  flex: 1;
  justify-content: center;
  align-items: flex-start;
  width: 100%;
  display: flex;
  position: relative;
}
.track-vertical-alt {
  position: absolute;
  white-space: nowrap;
  will-change: transform;
  animation: marquee-vertical-alt 30s linear infinite;
}
.flex-vertical {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  display: flex;
}
.flex-vertical.marquee-fix {
  grid-column-gap: 2rem;
  grid-row-gap: 2rem;
  margin-top: 30px;
}
.icon-container {
  border-radius: 8px;
    justify-content: center;
    align-items: center;
    width: 50%;
    box-shadow: rgb(49 48 48 / 33%) 0px 7px 29px 0px;
    /* box-shadow: rgb(0 0 0 / 25%) 0px 54px 55px, rgb(14 14 14 / 12%) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px; */
    height: 6vw;
    padding: 10px;
    /* BACKGROUND: #000; */
    display: flex;
}
.iconssssss {
  justify-content: center;
  align-items: flex-start;
  display: flex;
}

.track-vertical {
  flex-direction: column;
  flex: 1;
  justify-content: center;
  align-items: flex-start;
  width: 100%;
  display: flex;
  position: relative;
}
.track-vertical {
  position: absolute;
  white-space: nowrap;
  will-change: transform;
  animation: marquee-vertical 30s linear infinite;
}

@keyframes marquee-vertical-alt {
  0% {
    transform: translateY(-50%);
  }
  100% {
    transform: translateY(0%);
  }
}
.mark-div-right-choice {
  font-size: 20px;
  font-weight: 800;
}
@media screen and (max-width: 991px) {
  .padding-section-medium {
    padding-top: 4rem;
    padding-bottom: 4rem;
  }
}
@media screen and (max-width: 767px) {
  .padding-section-medium {
    padding-top: 3rem;
    padding-bottom: 3rem;
  }
}
@media screen and (max-width: 991px) {
  .heading-right-choice {
    font-size: 3.5rem;
  }
}
@media screen and (max-width: 767px) {
  .heading-right-choice {
    font-size: 2.5rem;
    line-height: 1.1;
  }
  .icon-container{
    width: 80%;
  }
}

@media screen and (max-width: 479px) {
  .heading-right-choice {
    font-size: 2rem;
  }
}
@media screen and (max-width: 991px) {
    .layout-2col_component {
        grid-column-gap: 3rem;
        grid-row-gap: 3.9rem;
        grid-template-columns: 1fr;
        grid-auto-flow: row;
        min-height: auto;
    }
}
@media screen and (max-width: 767px) {
    .layout-2col_component {
        grid-column-gap: 3.4rem;
        grid-row-gap: 3.4rem;
        grid-template-rows: auto;
        grid-template-columns: 1fr;
    }
}
@media screen and (max-width: 479px) {
    .layout-2col_component {
        grid-column-gap: 1.9rem;
        grid-row-gap: 1.9rem;
        grid-template-columns: 1fr;
    }
}
@media screen and (max-width: 991px) {
    #w-node-_822acfd4-cd8c-5996-f34b-cf01f7beac10-09d0b1db {
        order: 9999;
    }
}
@media screen and (max-width: 479px) {
    .flex-horizontal {
        grid-column-gap: 1.5rem;
        grid-row-gap: 1.5rem;
    }
}
@media screen and (max-width: 991px) {
    .marquee {
        height: 60vh;
    }
}
@media screen and (max-width: 767px) {
    .marquee {
        height: 50vh;
    }
}
@media screen and (max-width: 479px) {
    .marquee {
        height: 80vw;
    }
}
@media screen and (max-width: 479px) {
    .flex-vertical.marquee-fix {
        margin-top: 15px;
    }
}
@media screen and (max-width: 991px) {
    .icon-container {
        height: 20vw;
    }
}
@media screen and (max-width: 767px) {
    .icon-container {
        height: 25vw;
    }
}
@media screen and (max-width: 767px) {
    .track-vertical {
        align-items: center;
    }
}

.padding-section-medium ::-webkit-scrollbar{
  width: 3px;
}
.padding-section-medium ::-webkit-scrollbar-thumb{
  background:linear-gradient(transparent,#ffffff);
  border-radius: 6px;
}
.padding-section-medium::-webkit-scrollbar-thumb:hover{
  background:linear-gradient(transparent,#000000);
}