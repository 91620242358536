.hall-of-fame {
  background-color: #000;
  /* background: url('http://demo.amytheme.com/movie/demo/elementor-single-cinema/wp-content/uploads/sites/2/2022/05/bg_topmovie.png'); */
  -webkit-text-stroke-color: #fff;
  -webkit-text-fill-color: inherit;
  background-clip: border-box;
  height: 100vh;
  min-height: 0;
  padding-top: 80px;
  padding-bottom: 50px;
}
.div-block-218 {
  max-width: none;
  margin-bottom: 50px;
  margin-left: auto;
  margin-right: auto;
}
.image-204 {
  width: 130px;
  margin-left: auto;
  margin-right: auto;
  display: block;
}
.hof-heading {
  color: #fff;
  text-align: center;
  letter-spacing: 10px;
  margin-bottom: 0;
  padding-top: 0;
  padding-bottom: 10px;
  /* font-family: Poppins, sans-serif; */
  font-size: 80px;
  font-weight: 800;
  line-height: 90px;
}
.white.large {
  font-size: 22px;
  line-height: 150%;
}
.paragraph.white.center {
  flex-flow: column;
  flex: 0 auto;
  justify-content: flex-start;
  align-items: center;
  width: auto;
  max-width: 700px;
  height: auto;
  padding-top: 0;
  padding-bottom: 0;
  font-size: 17px;
  line-height: 30px;
  text-decoration: none;
  display: block;
}
.paragraph.white.center.large {
  margin-left: auto;
  margin-right: auto;
  padding-top: 0;
  text-align: center;
  color: #fff;
  padding-bottom: 0;
  font-size: 24px;
  line-height: 150%;
}
.project-link-card {
  grid-column-gap: 20px;
  /* background-image: url(https://d3e54v103j8qbb.cloudfront.net/img/background-image.svg); */
  background-size: cover;
  border-radius: 50px;
  flex-direction: column;
  justify-content: space-between;
  width: 35vh;
  min-width: auto;
  height: 50vh;
  margin-bottom: 8px;
  margin-left: 0;
  margin-right: 0;
  padding: 0;
  display: block;
  overflow: visible;
}
.div-100 {
  width: 100%;
  height: 100%;
}
.div-text {
  border: 1px #424242;
  border-radius: 50px;
  flex-direction: column;
  justify-content: flex-end;
  width: 100%;
  height: 100%;
  padding: 2rem;
  display: flex;
  position: relative;
  z-index: 5;
  overflow: visible;
}
.hof-artistname {
  color: #fff;
  letter-spacing: -0.4px;
  padding-bottom: 10px;
  font-size: 26px;
  font-weight: 700;
  line-height: 32px;
  margin-top: 0;
  margin-bottom: 0;
  padding-top: 8px;
}
.artistcard-text {
  color: #fff;
  margin-bottom: 0;
  padding-top: 0;
  padding-bottom: 0;
  /* font-family: Averta, Arial, sans-serif; */
  font-size: 14px;
  font-weight: 400;
  margin-top: 0;
  line-height: 20px;
}
.div-block-215 .swiper-slide {
  width: 245px !important;
}
.div-block-215 .overlay {
  left: 9px !important;
}
@media screen and (max-width: 479px) {
  .hall-of-fame {
    border: 0 #fff;
    height: 110vh;
    max-height: 130vh;
    margin-bottom: 0;
    padding-top: 40px;
    padding-bottom: 20px;
    overflow: hidden;
  }
}
@media screen and (max-width: 479px) {
  .hof-heading {
    letter-spacing: 5px;
    padding-top: 0;
    padding-bottom: 20px;
    font-size: 35px;
    line-height: 30px;
  }
}
@media screen and (max-width: 479px) {
  .paragraph.white.center {
    padding-left: 0;
    font-size: 16px;
    line-height: 26px;
  }
}
@media screen and (max-width: 767px) {
  .paragraph.white.center {
    text-align: center;
  }
}
@media screen and (max-width: 991px) {
  .paragraph.white.center {
    text-align: center;
    padding: 10px 0 0;
  }
}
@media screen and (max-width: 479px) {
  .paragraph.center.white.large {
    font-size: 22px;
  }
}

.project-link-card:after {
    content: '';
    position: absolute;
    bottom: 6px;
    left: 0px;
    width: 104%;
    height: 25%;
    background: linear-gradient(to top, rgba(0, 0, 0, .8) 0, rgba(0, 0, 0, 0) 100%);
    pointer-events: none;
    z-index: 1;
    border-radius: 0px 0px 42px 42px;
}
