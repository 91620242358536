.hamburger {
  display: inline-block;
  left: 0px;
  position: relative;
  top: 3px;
  -webkit-transition: all 0.3s ease-in-out 0s;
  transition: all 0.3s ease-in-out 0s;
  width: 26px;
  z-index: 999;
}
.hamburger .line {
  background: #76009f;
  display: block;
  height: 3px;
  border-radius: 3px;
  margin-top: 6px;
  margin-bottom: 6px;
  margin-left: auto;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}
.hamburger .line:nth-child(1) {
  width: 20px;
}
.hamburger .line:nth-child(2) {
  width: 26px;
}
.hamburger .line:nth-child(3) {
  width: 22px;
}
.hamburger .line {
  background: #76009f !important;
}
@media only screen and (min-width: 1200px) and (max-width: 1350px) {
  .nav-header {
    width: 16rem;
  }
}

.agentnav .header {
  height: 5rem;
  z-index: 1;
  position: relative;
  padding: 0rem;
  /* background-color: #e5e5e5; */
  z-index: 3;
  padding-left: 15.563rem;
  transition: all 0.2s ease;
}
@media only screen and (min-width: 1200px) and (max-width: 1350px) {
  .agentnav .header,
  [data-sidebar-style="overlay"] .header {
    width: 100%;
    padding-left: 17rem;
  }
}
.agentnav .header {
  position: sticky;
  top: 0;
  width: 100%;
}
.agentnav .header {
  background-color: #18042b;
  color: #fff;
}
.agentnav .header * {
  color: #fff;
}
.agentnav .header .header-content {
  height: 100%;
  /* padding-left: 6.0125rem; */
  padding-right: 2.675rem;
  align-items: center;
  display: flex;
  /* justify-content: space-between; */
}
.agentnav .header .navbar .navbar-collapse {
  height: 100%;
  width: 100%;
}
.agentnav .header-right .nav-item {
  height: 100%;
  display: flex;
  align-items: center;
}
.agentnav .header-right > li:not(:first-child) {
  padding-left: 1.25rem;
}
.agentnav .header-right .nav-item .nav-link {
  color: #464a53;
  color: #fff;
  font-size: 18px;
}
.header-right .header-profile > a.nav-link {
  padding: 0;
  display: flex;
  align-items: center;
}
.header-right .header-profile > a.nav-link .header-info {
  padding-right: 20px;
  text-align: right;
}
.header-right .header-profile > a.nav-link .header-info span {
  font-size: 16px;
  color: #fff;
  display: block;
  font-weight: 500;
}
.header-right .header-profile img {
  width: 40px;
  height: 40px;
  border-radius: 0.5rem;
}
.header-left {
  height: 100%;
  display: flex;
  align-items: center;
}
.header-left .dashboard_bar {
  font-size: 30px;
  font-weight: 500;
  /* color: #000; */
}
.header-right .dropdown-menu {
  box-shadow: none;
}
.header-right .header-profile .dropdown-menu {
  padding: 15px 0;
  width: 12.5rem;
  min-width: 12.5rem;
  background-color: #090111;
  /* box-shadow: 0px 0px 0px 1px rgba(255, 255, 255, 0.1); */
}
.header-right .header-profile .dropdown-item {
  padding: 8px 24px;
}

.header-right .header-profile .dropdown-item:active {
  background-color: #76009f !important;
}

.header-right .header-profile .dropdown-item:hover {
  background-color: #76009f !important;
}

.deznav {
  width: 13rem;
  padding-bottom: 0;
  height: calc(100% - 5rem);
  position: absolute;
  top: 5rem;
  padding-top: 0;
  z-index: 5;
  background-color: #fff;
  transition: all 0.2s ease;
  box-shadow: 0px 15px 30px 0px rgba(0, 0, 0, 0.02);
}
@media only screen and (min-width: 1200px) and (max-width: 1350px) {
  .deznav {
    width: 16rem;
  }
}
.deznav {
  margin-top: 0;
}
.deznav {
  background-color: #18042b;
}
@media (min-width: 1199px) {
  .deznav {
    position: fixed;
  }
}
.deznav {
  position: fixed;
}
.deznav .navbar {
  position: relative;
  height: 100%;
  overflow: scroll;
  border-top-left-radius: 0px;
  border-top-right-radius: 0px;
  scrollbar-width: none;
}
.deznav * {
  color: #fff;
}
.deznav ul {
  padding: 0;
  margin: 0;
  list-style: none;
}
.deznav .metismenu {
  display: flex;
  flex-direction: column;
  padding-top: 15px;
}
@media (min-width: 992px) {
  .deznav .navbar-expand-lg .navbar-nav {
    flex-direction: column;
  }
}
.deznav .sidebar_menu {
  width: 100%;
  --bs-accordion-bg: transparent;
  --bs-accordion-color: #fff;
}
.deznav .accordion-item {
  color: #fff;
  background-color: transparent;
  border: none;
}
.deznav .accordion-button {
  color: #fff;
}
.deznav .accordion-button::after {
  filter: invert(1);
}
.deznav svg {
  margin-right: 10px;
}
.deznav .sub-nav {
  padding-left: 30px;
}
.deznav .accordion-button:not(.collapsed) {
  background-color: transparent;
  color: #fff;
  /* border: none; */
  /* box-shadow: none; */
}
.deznav .accordion-button:focus {
  box-shadow: none;
}
.add_release_btn {
  color: #3d3b42;
  position: relative;
  padding: 15px 30px 15px 30px;
  box-sizing: border-box;
  background-clip: padding-box;
  border: none;
  border-radius: 40px;
  overflow: visible;
  font-size: 16px;
  height: auto;
  font-weight: bold;
  text-transform: none;
  letter-spacing: -0.01em;
  text-decoration: none;
  transition: background 0.3s ease-out, color 0.3s;
  background: linear-gradient(270deg, #ff6600 0%, #7b16d9 100%);
  color: #fff;
}

.header .nav-link {
  position: relative; /* Create a positioning context for the pseudo-element */
  color: #000; /* Default link color */
  transition: color 0.3s ease; /* Smooth transition for color change */
}

.header .nav-link:hover {
  color: #76009f; /* Change color on hover */
}

.header .nav-link::after {
  content: "";
  position: absolute;
  width: 100%; /* Full width of the link */
  height: 2px; /* Height of the underline */
  background: #76009f; /* Color of the underline */
  left: 0;
  bottom: 0; /* Position at the bottom of the link */
  transform: scaleX(0); /* Initial scale is 0 (invisible) */
  transition: transform 0.3s ease; /* Smooth transition for the scale */
}

.header .nav-link:hover::after {
  transform: scaleX(1); /* Scale to full width on hover */
}

.header .profilenavlink:hover::after {
  display: none;
}
@media (max-width: 768px) {
  .agentnav .header .header-content {
    display: none;
  }
  .header .menu_navbar {
    display: inline-block !important;
  }
}
@media (min-width: 768px) {
  .header .sidebar-container {
    display: none;
  }
}
/* Sidebar.css */
.sidebar-container {
  width: 250px;
  background-color: #18042a;
  color: #fff;
  height: 100vh;
  position: fixed;
  top: 80px;
  left: 0;
  padding-top: 0px;
}

.sidebar-container .sidebar-link {
  color: #ffffff;
  padding: 15px 20px;
  text-decoration: none;
  font-size: 16px;
}

.sidebar-container .sidebar-link:hover {
  background-color: #0c0116;
  color: #a777d1;
}
