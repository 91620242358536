.section-logo {
  padding: 60px 0px;
}
.padding-section-small {
  padding-top: 3rem;
  padding-bottom: 3rem;
}
.text-align-center {
  text-align: center;
}
.center-title_wrapper {
  text-align: center;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
}

.tag-component {
  z-index: 5;
  color: rgba(255, 255, 255, 0.9);
  background-color: rgba(25, 17, 51, 0.5);
  border: 1px solid rgba(248, 246, 254, 0.2);
  border-radius: 99px;
  margin-bottom: 0;
  padding: 0.3rem 1.2rem;
  display: inline-block;
  position: relative;
}
#w-node-a882b131-d9f3-7fba-0812-502a7b258843-7b258843,
#w-node-_4eb95e6f-0d15-e86a-ae3c-c1888aade1d9-8aade1d9 {
  grid-area: span 1 / span 1 / span 1 / span 1;
}
.margin-small h2 {
  color: #fff;
  margin-top: 0;
  margin-bottom: 0;
  font-size: 77px;
  font-weight: 700;
  line-height: 1.2;
}
.logo-large_content-wrapper {
  background-color: rgba(238, 238, 238, 0);
  justify-content: center;
  align-items: flex-start;
  width: 100%;
  max-width: 75rem;
  margin-top: 3rem;
  margin-left: auto;
  margin-right: auto;
  display: flex;
}
.our-storee .logo-large-list {
  grid-column-gap: 1.5rem;
  grid-row-gap: 3rem;
  grid-template-rows: auto auto auto;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
  align-content: center;
  justify-content: center;
  align-items: center;
  justify-items: center;
  width: 100%;
}
.logo-large_wrapper {
  /* background-color: #fdfdfd; */
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  width: 100%;
  min-height: 125px;
  /* box-shadow: rgb(255 255 255 / 24%) 0px 3px 8px; */
  border: 1px solid #1f1f1f;
  padding-top: 10px;
  padding-bottom: 10px;
  display: flex;
}
#w-node-a285a9ca-1a3f-5aff-efb5-9072d5e0f8a8-d5e0f89b,
#w-node-a285a9ca-1a3f-5aff-efb5-9072d5e0f8aa-d5e0f89b,
#w-node-a285a9ca-1a3f-5aff-efb5-9072d5e0f8ac-d5e0f89b,
#w-node-a285a9ca-1a3f-5aff-efb5-9072d5e0f8ae-d5e0f89b,
#w-node-a285a9ca-1a3f-5aff-efb5-9072d5e0f8b0-d5e0f89b,
#w-node-a285a9ca-1a3f-5aff-efb5-9072d5e0f8b2-d5e0f89b,
#w-node-a285a9ca-1a3f-5aff-efb5-9072d5e0f8b4-d5e0f89b,
#w-node-a285a9ca-1a3f-5aff-efb5-9072d5e0f8b6-d5e0f89b,
#w-node-a285a9ca-1a3f-5aff-efb5-9072d5e0f8b8-d5e0f89b,
#w-node-a285a9ca-1a3f-5aff-efb5-9072d5e0f8ba-d5e0f89b,
#w-node-a285a9ca-1a3f-5aff-efb5-9072d5e0f8bc-d5e0f89b,
#w-node-a285a9ca-1a3f-5aff-efb5-9072d5e0f8be-d5e0f89b,
#w-node-a285a9ca-1a3f-5aff-efb5-9072d5e0f8c0-d5e0f89b,
#w-node-a285a9ca-1a3f-5aff-efb5-9072d5e0f8c2-d5e0f89b,
#w-node-a285a9ca-1a3f-5aff-efb5-9072d5e0f8c4-d5e0f89b {
  justify-self: center;
}
.logo-large_logo {
  max-height: 80px;
  width: 150px;
}
.our-store-paraa {
  font-size: 23px;
  font-weight: 500;
  color: #fff;
  margin-top: 20px;
}
.our-store-btnn {
  border: none;
  margin: 20px 0px;
  padding: 20px 65px !important;
}
@media (max-width: 992px) {
  .our-storee .logo-large-list {
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  }
}
@media (max-width: 768px) {
  .margin-small h2 {
    font-size: 45px;
  }
  .our-store-paraa {
    font-size: 16px;
  }
  .our-store-btnn {
    padding: 20px 45px !important;
  }
  .our-storee .logo-large-list {
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }
}
@media (max-width: 442px) {
  .our-storee .logo-large-list {
    grid-template-columns: 1fr 1fr 1fr;
  }
}
