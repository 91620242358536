.gSJmLi.gSJmLi {
  padding-top: 42px;
  padding-bottom: 72px;
}
.hitOkj {
  display: table;
  width: 100%;
  border-collapse: collapse;
  border-spacing: 0px;
  position: relative;
  z-index: 0;
}
.bZFcpp {
  display: table-header-group;
}
.hwGmHZ {
  display: table-row;
  color: inherit;
  outline: 0px;
  vertical-align: middle;
  border-bottom: 1px solid rgb(44, 60, 77);
}
.eqTeAr {
  display: table-cell;
  padding: 12px;
  vertical-align: inherit;
  color: rgb(173, 183, 191);
  font-weight: 700;
}
.jrPndC.jrPndC {
  width: 40%;
  text-align: left;
}
.eqTeAr:first-child {
  padding-left: 0px;
}
.eqhRgK.eqhRgK {
  display: inline-flex;
  -webkit-box-align: center;
  align-items: center;
}
.jTWLdb {
  color: rgb(249, 250, 251);
  font-size: 2.25rem;
  line-height: 52px;
}
.jMQofu {
  margin-top: 0px;
  margin-bottom: 16px;
}
.laFXnL.laFXnL {
  font-weight: 700;
}
.eqTeAr {
  display: table-cell;
  padding: 12px;
  vertical-align: inherit;
  color: rgb(173, 183, 191);
  font-weight: 700;
}
.ckdQlM {
  width: 10%;
}
.bnHeWV.bnHeWV {
  text-align: left;
}
.eqhRgK.eqhRgK {
  display: inline-flex;
  align-items: center;
}
.IiRpi {
  display: flex;
  flex-direction: column;
  margin: 0px;
}
.IiRpi > .sc-9ueux3-0:not(:last-child) {
  margin-bottom: 4px;
}
.bPrCdf {
  color: rgb(249, 250, 251);
  font-size: 0.9375rem;
  line-height: 24px;
}
.etWezL {
  color: rgb(249, 250, 251);
  line-height: 20px;
}

.hEfsiA.hEfsiA {
  font-weight: 400;
}
.jiFHgs.jiFHgs {
  color: rgb(234, 91, 112);
  font-weight: 400;
  text-decoration: line-through;
}
.cyUqQu {
  display: table-row-group;
}
.jOclRz {
  display: table-row;
  color: inherit;
  outline: 0px;
  vertical-align: middle;
  border-bottom: 1px solid rgb(44, 60, 77);
}
.fFqbdp {
  display: table-cell;
  padding: 12px;
  vertical-align: inherit;
}

.fFqbdp:first-child {
  padding-left: 0px;
}

.jOclRz .sc-31nydx-0 {
  position: relative;
}
.XwNAG {
  display: flex;
  flex-direction: row;
  margin: 0px;
}
.geDnnq.geDnnq {
  -webkit-box-align: center;
  align-items: center;
}
.XwNAG > .sc-9ueux3-0:not(:last-child) {
  margin-right: 4px;
}
.hSHtlh.hSHtlh {
  margin-left: 4px;
  margin-right: 4px;
}
.dOsGV {
  display: flex;
  -webkit-box-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  align-items: center;
  border-radius: 8px;
  width: 28px;
  min-width: 28px;
  height: 28px;
  font-size: 16px;
  color: rgb(116, 255, 180);
  background: rgba(29, 182, 145, 0.32);
}
.gChFoY.gChFoY {
  font-weight: 400;
}
.jOclRz .sc-31nydx-0::after {
  content: " ";
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0px;
  left: 0px;
  z-index: -1;
}
.jOclRz .sc-31nydx-0:first-of-type::after {
  width: calc(100% + 12px);
  right: 0px;
  left: auto;
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
}
.jDMKZt.jDMKZt {
  width: 24px;
  height: 24px;
  color: rgb(50, 203, 203);
}
.jviYgz.jviYgz {
  width: 24px;
  height: 24px;
  color: rgb(108, 119, 130);
}
.kThsXE {
  display: flex;
  -webkit-box-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  align-items: center;
  border-radius: 8px;
  width: 28px;
  min-width: 28px;
  height: 28px;
  font-size: 16px;
  color: rgb(240, 182, 255);
  background: rgba(164, 103, 241, 0.32);
}
.cyerOT {
  display: flex;
  -webkit-box-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  align-items: center;
  border-radius: 8px;
  width: 28px;
  min-width: 28px;
  height: 28px;
  font-size: 16px;
  color: rgb(255, 227, 179);
  background: rgba(253, 146, 0, 0.32);
}
.hvFPMK {
  display: flex;
  -webkit-box-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  align-items: center;
  border-radius: 8px;
  width: 28px;
  min-width: 28px;
  height: 28px;
  font-size: 16px;
  color: rgb(184, 210, 242);
  background: rgba(51, 116, 191, 0.32);
}
.kMxCKm {
  display: flex;
  -webkit-box-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  align-items: center;
  border-radius: 8px;
  width: 28px;
  min-width: 28px;
  height: 28px;
  font-size: 16px;
  color: rgb(255, 171, 221);
  background: rgba(234, 38, 132, 0.32);
}
.jKIEFQ {
  display: flex;
  -webkit-box-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  align-items: center;
  border-radius: 8px;
  width: 28px;
  min-width: 28px;
  height: 28px;
  font-size: 16px;
  color: rgb(246, 224, 127);
  background: rgba(238, 194, 0, 0.32);
}

@media (max-width: 448px) {
  .XwNAG > .sc-9ueux3-0:not(:last-child) {
    display: none;
  }

  .eqTeAr{
    padding: 5px;
  }

  .pricing_toggleOption__EOUHy{
    padding-inline: 10px;
  }

  .price_table_container{
    padding: 5px !important;
  }

  .fFqbdp{
    padding: 8px;
  }

  .gSJmLi.gSJmLi {
    padding-top: 22px;
    padding-bottom: 42px;
  }
}
