/* Main Card */
.payment-card {
    background-color: #1d1d1d; /* Darker shade for the card */
    border-radius: 12px; /* Rounded corners */
    border: none; /* No border */
    padding: 20px;
  }
  
  .info-box {
    background-color: #2a2a2a; /* Slightly lighter background for info boxes */
    padding: 15px;
    border-radius: 8px; /* Smooth rounded edges */
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .info-label {
    color: #9e9e9e; /* Muted grey for labels */
    font-size: 0.9rem;
  }
  
  .info-value {
    color: #ffffff; /* White color for the values */
    font-size: 1.1rem;
    font-weight: bold;
  }
  
  .payment-card hr {
    border-color: #383838; /* Lighter divider */
  }
  
  button {
    font-weight: bold;
  }
  
  button:hover {
    background-color: #3c3c3c; /* Darker hover effect for button */
  }
  
  /* Ensure proper spacing */
  .mb-3 {
    margin-bottom: 1rem !important;
  }
  