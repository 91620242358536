.music-supervision .sync-buyers .music-supervision {
  padding-top: 125px;
}
.music-supervision .m-b-md {
  margin-bottom: 15px;
}
.music-supervision img {
  height: auto;
  max-width: 100%;
}
.music-supervision .gradient-divider {
  margin-top: 35px;
  margin-bottom: 35px;
  display: block;
  height: 1px;
}
.music-supervision .svg-button {
  margin-left: auto;
  margin-right: auto;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  margin-top: 25px;
}
.gradient_border_btn {
  color: #fff !important;
  border-radius: 50px !important;
  border: 4px double transparent !important;
  background-image: linear-gradient(rgb(0, 0, 0), rgb(0, 0, 0)),
    radial-gradient(circle at left top, rgb(1, 110, 218), rgb(217, 0, 192)) !important;
  background-origin: border-box !important;
  background-clip: padding-box, border-box !important;
}
.gradient_border_btn:hover {
  color: #fff;
  border-radius: 50px !important;
  border: 6px double transparent !important;
  background-image: linear-gradient(rgb(0, 0, 0), rgb(0, 0, 0)),
    radial-gradient(circle at left top, rgb(1, 110, 218), rgb(217, 0, 192));
  background-origin: border-box;
  background-clip: padding-box, border-box;
}
.explore_btn {
  max-width: 200px;
  float: right;
}
.section_section__I0u9H {
  padding: 100px 20px;
  background-size: cover;
  position: relative;
  align-items: center;
  display: flex;
  background: #000;
}
.container_left__yyZsr {
  display: flex;
  flex-direction: row-reverse;
}
.content_content__hiCNB {
  display: flex;
  flex-direction: column;
  row-gap: 20px;
  flex: 1;
  width: 100%;
}
.content_marginAuto__3jHEC {
  margin: auto;
}
.content_lg__0npCb {
  row-gap: 20px;
}
.button-container_buttonContainer__YRgZl {
  display: flex;
  flex-direction: row;
  -moz-column-gap: 10px;
  column-gap: 10px;
  row-gap: 10px;
  flex-wrap: wrap;
  align-items: center;
}
.media_default__Mq0yp {
  position: relative;
  flex: 0 0 25%;
}
.media_medium__yJzWA {
  flex: 0 0 40%;
  width: 40%;
  margin: auto;
}
.media_image__CtmQY {
  margin: auto;
  width: 100%;
  height: auto;
}

.single_service_ul li p {
  font-size: 24px;
  color: #fff;
}
.single_service_ul li {
  display: flex;
}
.single_service_ul li svg {
  color: blueviolet;
  margin-right: 10px;
  max-width: 30px;
}

.music_services .section-speciality {
  padding: 60px 0;
}

.music_services .relative {
  position: relative;
}

.music_services .leading-3_2 {
  line-height: 3.2;
}

.music_services .mb-3 {
  margin-bottom: 10px;
}

.music_services .mb-4 {
  margin-bottom: 28px;
}

.music_services .text-21 {
  font-size: 21px;
}

.music_services .before-content::before {
  content: "";
  width: 36px;
  height: 5px;
  position: absolute;
  bottom: 0;
  left: 0;
  background-color: #fd562a;
}

.music_services .section-speciality__box {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex-wrap: wrap;
}

.music_services .box-item {
  border-radius: 20px;
  width: 100%;
  padding: 40px 45px;

  cursor: pointer;
  background-color: transparent;
  color: white;
  text-align: left;

  /* border: 3px solid transparent;
  border-image: linear-gradient(to right, rgba(129, 21, 255, 1) 0%, rgba(255, 42, 208, 1) 50%, rgba(255, 143, 42, 1) 100%);
  border-image-slice: 1; */

  border-image-slice: 1;
  overflow: hidden;
  padding: 10px 20px;
  position: relative;
  text-align: center;
  transition: all 0.3s ease 0s;
  /* width: 100%;
  height: 100%; */
  background: #000;
  border-radius: 20px;
}
.featuresDiv2 {
  padding: 0.2rem;
  background: linear-gradient(90deg, #8115ff 0, #ff2ad0 50%, #ff8f2a);
  border-radius: 20px;
  max-width: 270px;
  max-height: 326px;
  margin-bottom: 30px;
}

.music_services .box-item h3 {
  margin-bottom: 23px;
}

.music_services .box-item a {
  color: white;
  font-size: 1.25rem;
  text-decoration: none;
}

.music_services .box-item p {
  margin-top: 14px;
  line-height: 1.7;
  margin-bottom: 16px;
}

.music_services .mt-45 {
  margin-top: 45px;
}

.music_services .mt-neg-45 {
  margin-top: -45px;
}

.music_services .mt-neg-60 {
  margin-top: -60px;
}

.music_services .mr-28 {
  margin-right: 28px;
}

@media (max-width: 1200px) {
  .music_services .section-speciality__box {
    justify-content: center;
  }
  .featuresDiv2 {
    margin: 10px 0px !important;
  }
}
@media (max-width:768px){
  .media_medium__yJzWA {
    width: 100%;
  }
}