@import url("https://fonts.googleapis.com/css2?family=Fira+Sans:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Montserrat:ital,wght@0,100..900;1,100..900&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&family=Montserrat:wght@100;200;300;400;500;600;700;800&display=swap");

* {
  margin: 0;
  padding: 0;
  font-family: "Montserrat", "Lato", sans-serif !important;
}
.mark,
mark {
  padding: 0px !important;
}

.swiper {
  width: 100%;
  height: 100%;
}

.swiper-slide {
  text-align: center;
  font-size: 18px;
  background: #fff;

  /* Center slide text vertically */
  display: flex;
  justify-content: center;
  align-items: center;
}

.swiper-slide img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.swiper-slide {
  background: transparent !important;
}

.scroll-to-top {
  position: fixed;
  bottom: 20px;
  right: 20px;
  background-image: linear-gradient(-45deg, #00ef86, #008cff, #ec3258, #fcda00);
  color: #fff;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
}

.scroll-to-top.show {
  opacity: 1;
}

.scroll-to-top.hide {
  opacity: 0;
  pointer-events: none;
}

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0;
}

@media (max-width: 768px) {
  .make_small_mobile {
    font-size: 55px !important;
  }
}
/* 
.content-body {
  margin-left: 13rem;
  background-color: #000;
}
@media only screen and (min-width: 1200px) and (max-width: 1350px) {
  .content-body {
    margin-left: 16rem;
  }
} */
.content-body {
  min-height: calc(100vh - 5rem);
  overflow: auto;
  padding: 30px 10px;
  color: #fff;
}
.content-body * {
  color: #fff;
}

.content-body .text-danger path {
  --bs-text-opacity: 1;
  color: rgba(220, 53, 69, 1) !important;
}

.content-body .text-warning path {
  --bs-text-opacity: 1;
  color: rgba(255, 193, 7, 1) !important;
}

.content-body .text-success path {
  --bs-text-opacity: 1;
  color: rgba(25, 135, 84, 1) !important;
}

.content-body .text-approved path {
  --bs-text-opacity: 1;
  color: rgb(25, 65, 135) !important;
}

.table-dark{
  --bs-table-border-color: #1d1d1d !important;
}