.plans-container .header {
  text-align: center;
  font-size: 40px;
  font-weight: 900;
}
.plans-container {
  font-family: "Roboto", Helvetica, sans-serif;
  padding: 40px;
  min-height: 100vh;
}
.plans-container .subheader {
  text-align: center;
  color: #b9b9b9;
  font-size: 18px;
  font-weight: 400;
  margin-bottom: 30px;
}
.per-release-plan {
  height: 70px;
}
.plans-container .header {
  text-align: center;
  font-size: 40px;
  font-weight: 900;
}
.plan-cards {
  color: #fff;
  display: flex;
  flex-direction: row;
  align-self: center;
  margin: 2em 0 1em;
  width: 100%;
  overflow-x: auto;
  scroll-snap-type: x mandatory;
  padding: 10px;
  mask-image: linear-gradient(
    to right,
    transparent,
    #000 10px,
    #000 calc(100% - 10px),
    transparent
  );
  -webkit-mask-image: linear-gradient(
    to right,
    transparent,
    #000 10px,
    #000 calc(100% - 10px),
    transparent
  );
}
.plan-cards {
  scrollbar-width: auto;
  scrollbar-color: #333 #333333;
  height: auto;
}

.plan-blue {
  --plan-color-1: #008cff;
  --plan-color-2: #3d00cc;
  filter: grayscale(50%) contrast(0.9);
}
.plan-red {
  --plan-color-1: #ec3258;
  --plan-color-2: #c000b6;
  filter: grayscale(50%) contrast(0.9);
}
.plan-yellow {
  --plan-color-1: #fcda00;
  --plan-color-2: #ec3258;
  filter: grayscale(50%) contrast(0.9);
}
.plan-green {
  --plan-color-1: #00ef86;
  --plan-color-2: #008eb9;
  filter: grayscale(50%) contrast(0.9);
}
.plan-cards .plan.disabled,
.plan .plan-internal-wrapper {
  position: relative;
  overflow: hidden;
  --plan-color-1: #363636;
  --plan-color-2: #2b3538;
}
.plans .plan.disabled,
.plan-cta.disabled {
  /* filter: grayscale(50%) contrast(0.9); */
  filter: unset !important;
  opacity: 0.4;
  pointer-events: none;
}
.plan-cards .plan {
  background-clip: padding-box;
  background-color: #1a1a1a;
  border-radius: 20px;
  color: #e8e6e3;
  display: flex;
  flex: 0 0 250px;
  flex-direction: column;
  justify-content: space-between;
  margin: 0 1em;
  padding: 25px;
  position: relative;
  scroll-snap-align: center;
  text-align: center;
  transition: all 100ms ease-in;
}
.plan-cards .plan:before {
  border-radius: 25px;
  background: linear-gradient(to bottom right, #555);
  content: "";
  inset: -6px;
  position: absolute;
  pointer-events: none;
  transition: all 100ms ease-in;
  z-index: -2;
  background: linear-gradient(
    to bottom right,
    var(--plan-color-1),
    var(--plan-color-2)
  );
}
.plan .plan-copy {
  height: 100%;
}
.plan-cards .plan-name {
  font-weight: bold;
  font-size: 14px;
  letter-spacing: 2px;
  text-transform: uppercase;
  white-space: nowrap;
}
.plan-cards .plan-price {
  font-weight: 900;
  font-size: 40px;
  letter-spacing: 0;
  line-height: 1;
  margin: 0.25em 0 0;
}
.plan .plan-currency {
  font-size: 20px;
  vertical-align: top;
}
.plan .plan-iteration {
  font-size: 14px;
  font-weight: 400;
}
.plan .plan-blurb {
  color: #fff;
  font-size: 85%;
  padding: 1em 0 0.5em;
}
.plan .plan-blurb .highlight {
  color: #008cff;
  font-weight: 700;
}
.plan ul {
  padding: 0;
  margin: 0;
  list-style: none;
  width: 100%;
  max-height: 110px;
  text-align: left;
  overflow: hidden;
  margin-top: 10px;
  transition: all 300ms ease-in;
}
.plan ul li {
  color: #e8e6e3;
  position: relative;
  padding: 0;
  margin: 8px 0;
  padding-left: 6px;
  margin-left: 14px;
  font-size: 13px;
}
form.button_to {
  display: inline-block;
}
.plan .plan-cta {
  padding: 15px 25px;
  border-radius: 50px;
  font-size: 15px;
  font-weight: 900;
  letter-spacing: 2px;
  white-space: nowrap;
  text-transform: uppercase;
  color: #e8e6e3;
  background: transparent;
  margin: 1em auto 1em;
  z-index: 100;
  border: 0;
  box-shadow: inset 0 0 0 3px #fff;
}
.plans-container .footer {
  color: gray;
  font-size: 0.8em;
  padding: 1em 0 1em 0.5em;
}
.plans-container .footer {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 0 20px;
}
.plans-container .footer p {
  color: #999;
  font-size: 14px;
}
.plans-container .footer .gst-disclaimer {
  font-weight: bold;
  margin-bottom: 2rem;
  color: white;
}
.plans-container .footer .plan-link {
  font-family: "Roboto", Helvetica, sans-serif;
  background: transparent;
  outline: none;
  color: #e8e6e3;
  padding: 15px 20px;
  border-radius: 100px;
  font-weight: 900;
  font-size: 12px;
  letter-spacing: 1px;
  text-transform: uppercase;
  border: 3px solid #303436;
  text-decoration: none;
}
.plans-container .footer .plan-link.continue_no_plan {
  border: none;
  padding-top: 2em;
  color: rgba(255, 255, 255, 0.6);
}
