.sign-up-container {
  /* background: url("https://offstep.com/wp-content/uploads/sites/10/2023/11/hero2023_opt-scaled.webp"); */
  background: #000;
  min-height: 140vh;
  background-size: cover;
  background-repeat: no-repeat;
  padding-bottom: 50px;
}
.col-1-signup-cont {
  padding: 50px 0px 100px;
}
.col-1-signup-head {
  font-size: 60px;
  font-weight: 700;
  color: #fff;
  line-height: 60px;
}
.col-1-signup-para {
  font-size: 20px;
  font-weight: 500;
  color: #fff;
  width: 400px;
  margin-top: 15px;
}
.col-1-signup-divs {
  position: relative;
  margin-top: 30px;
  background: #000000e3;
  box-shadow: 0 0 10px #e8e8e845;
  border-radius: 20px;
  padding: 40px;
  color: #fff;
  overflow: hidden;
}
.col-1-signup-ul {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}
.col-1-signup-ul p {
  margin-bottom: 0px !important;
}
.wrapper {
  position: relative;
  margin-left: 60px;
  background: #000000e3;
  box-shadow: 0 0 10px #e8e8e845;
  border-radius: 20px;
  padding: 40px;
  overflow: hidden;
}
.wrapper:hover {
  animation: animate 1s linear infinite;
}
/* @keyframes animate {
  100% {
    filter: hue-rotate(360deg);
  }
} */
/* .form-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  transition: 1s ease-in-out;
} */
.wrapper.active .form-wrapper.sign-in {
  transform: translateY(-450px);
}
.wrapper .form-wrapper.sign-up {
  position: absolute;
  top: 450px;
  left: 0;
}
.wrapper.active .form-wrapper.sign-up {
  transform: translateY(-450px);
}

.input-group {
  position: relative;
  margin: 30px 0;
  border-bottom: 2px solid #fff;
}
.input-group label {
  position: absolute;
  top: 50%;
  left: 5px;
  transform: translateY(-50%);
  font-size: 16px;
  color: #fff;
  pointer-events: none;
  transition: 0.5s;
}
.input-group input {
  width: 320px;
  height: 40px;
  font-size: 16px;
  color: #fff;
  padding: 0 5px;
  background: transparent;
  border: none;
  outline: none;
}
.input-group input:focus ~ label,
.input-group input:valid ~ label {
  top: -5px;
}
.remember {
  margin: -5px 0 15px 5px;
}
.remember label {
  color: #fff;
  font-size: 14px;
}
.remember label input {
  accent-color: #0ef;
}
/* button {
  position: relative;
  width: 100%;
  height: 40px;
  background: #0ef;
  box-shadow: 0 0 10px #0ef;
  font-size: 16px;
  color: #000;
  font-weight: 500;
  cursor: pointer;
  border-radius: 30px;
  border: none;
  outline: none;
} */
.signUp-link {
  font-size: 14px;
  text-align: center;
  margin: 15px 0;
}
.signUp-link p {
  color: #fff;
}
.signUp-link p a {
  color: #0ef;
  text-decoration: none;
  font-weight: 500;
}
.signUp-link p a:hover {
  text-decoration: underline;
}

.field {
  margin-bottom: 18px;
}
.form-signup-label {
  font-weight: 400;
  color: rgb(255 255 255 / 1) !important;
  font-size: 13px;

  width: 130px;
  color: #33475b;
  display: block;
  float: none;
  width: auto;
  font-weight: 500;
  line-height: 20px;
  padding-top: 0;
  margin-bottom: 4px;
}
.hs-form-required {
  color: red;
}
.hs-input {
  padding: 0 15px;
  border: none;
  min-height: 27px;
  /* border-radius: 10px !important; */
  background-color: transparent !important;
  padding-left: 1.25rem !important;
  padding-right: 1.25rem !important;
  color: rgb(255 255 255 / 1) !important;
  color: #fff;
  border-bottom: 1px solid #cbd6e2 !important;
  font-size: 16px;
  font-weight: normal;
  line-height: 22px;
  height: 52px !important;
  width: 100%;
}
.hs-form-field {
  width: 100%;
}
.inputs-list > li {
  display: block;
  padding: 0;
  width: 100%;
  padding-top: 0;
}
.inputs-list label {
  float: none;
  width: auto;
  padding: 0;
  line-height: 18px;
  white-space: normal;
  font-size: 13px;
  font-weight: normal;
}
.inputs-list {
  list-style: none;
}
.col-1-signup-cont ul {
  padding-left: 0px !important;
}
.hs-button {
  width: 100%;
  border: none;
  margin-block: 20px;
}

.hs-input option{
  color: #fff;
  background: #000;
}

.col-1-signup-cont .w33{
  width: 33%;
}

.col-1-signup-cont .w50{
  width: 50%;
}

@media (max-width: 768px) {
  .col-1-signup-cont {
    padding: 15px 0px 10px 0px;
  }
  .wrapper {
    margin-inline: 10px;
    padding: 20px 10px;
  }
  .sm-col-reverse {
    flex-direction: column-reverse;
  }
  .col-1-signup-head {
    font-size: 38px;
    line-height: 40px;
  }

  .col-1-signup-cont .d-flex{
    display: block !important;
  }
  
  /* .col-1-signup-cont .w33{
    width: 100%;
  }
  
  .col-1-signup-cont .w50{
    width: 100%;
  } */

  .col-1-signup-cont .display_flex{
    display: flex !important;
  }

  .col-1-signup-cont .ww50{
    width: 100%;
  }

  .col-1-signup-cont .hs-input{
    height: 35px !important;
  }
}