.section_section__I0u9H {
    padding: 100px 20px;
    background-size: cover;
    position: relative;
    align-items: center;
    display: flex;
}
@media screen and (min-width: 768px) {
    .section_alignCenter__x2Hse {
        text-align: center;
    }
}
.content_marginAuto__3jHEC {
    margin: auto;
}
.content_lg__0npCb {
    row-gap: 20px;
}
.carousel_item__4Z77Y.carousel_circle__Pte7w {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: transparent;
    text-align: center;
    flex: 0 0 100%;
    gap: 20px;
}
.carousel_item__4Z77Y.carousel_circle__Pte7w .carousel_itemImage__F407g {
    border-radius: 9999px;
    width: 150px;
    height: 150px;
    flex: 0 0 150px;
    -o-object-fit: cover;
    object-fit: cover;
}
.carousel_item__4Z77Y.carousel_circle__Pte7w .carousel_itemText__HtUwk {
    max-width: 600px;
}
.carousel_header__Fi6V7 {
    font-weight: 700;
    font-size: 20px;
    color: #fff;
}
.carousel_itemText__HtUwk p{
    color: #a1a1a1;
    font-size: 14px;
    line-height: 1.5;
}
.testimonial-containerr .swiper-button-lock{
display: block !important;
}
.testimonial-containerr .swiper-button-prev, .swiper-button-next{
    color: #fff !important;
    opacity: 1 !important;
}
.testimonial-containerr{
    margin-bottom: 50px;
}

@media (max-width:448px){
    .section_section__I0u9H {
        padding: 20px 5px;
    }
}