.music-upload-cont{
    padding: 20px;
    background-size: cover;
    background-position: center;
    margin: auto;
    background-color: #09070e;
    

}
.music-upload-cont .font-weight-bold{
    font-weight: 800 !important;
    font-size: 64px;
    line-height: 64px;
    padding-bottom: 0.5rem;
}
.music-head-desc{
font-size: 22px;
font-weight: 600;
color: #fff;
margin-bottom: 0px;
}

.single-blog {
    margin-top: 90px;
    padding: 0 60px 40px;
    background: #1D1D1D;
    box-shadow: 0px 0px 9px 0px rgba(0, 0, 0, 0.20);
    transition: all .3s ease-in;
}


.single-blog .overflow-hide img {
    margin-top: -60px;
    width: 100%;
}
.mtb-20-0 {
    margin: 20px 0;
}
.music-upload-image{
height: 378px;
width: 650px;
object-fit: cover;
}
.second-music-cont{
    padding: 20px 65px;
    position: relative;
}
.musicupload-head-three{
    font-size: 56px ;
}

@keyframes fadeInUp {
    0% {
        opacity: 0;
        -webkit-transform: translateY(20px);
        -ms-transform: translateY(20px);
        transform: translateY(20px);
    }
    100% {
        opacity: 1;
        -webkit-transform: translateY(0);
        -ms-transform: translateY(0);
        transform: translateY(0);
    }
}
.margin-bott{
    margin-bottom: 40px;
}
/* .second-music-cont:before{
    content: "";
    position: absolute;
    top: 50%;
    left: 50%;
    width: 35%;
    height: 35%;
    -webkit-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    border-radius: 50%;
   
    background: -o-linear-gradient(190deg, #8750f7 0%, rgba(115, 67, 210, 0) 100%);
    background: linear-gradient(260deg, #8750f7 0%, rgba(115, 67, 210, 0) 100%);
    -webkit-filter: blur(150px);
    filter: blur(150px);
} */
 @media (max-width:786px) {
    
    .music-upload-cont .font-weight-bold{
        font-size: 35px;
        line-height: 40px;
    }
    .music-head-desc {
        font-size: 16px;
        font-weight: 400;
    }
    .second-music-cont{
        padding: 10px 10px;
    }
    .margin-bott{
        margin-bottom: 40px;
    }
 }

 @media (min-width: 320px) and (max-width: 575px) {
    .three-d-album {
        background-image: none;
    }
}
@media (max-width: 575px) {
    .section-title.style-four {
        margin-bottom: 30px;
    }
}
@media (min-width: 320px) and (max-width: 991px) {
    .section-title.style-four p {
        padding: 0px 5%;
    }
}
@media (max-width: 575px) {
    .section-title.style-four p {
        padding: 0px 5%;
        font-size: 14px;
        line-height: 24px;
    }
}
@media (min-width: 320px) and (max-width: 575px) {
    .three-d-album .more-album {
        left: 10px;
        margin-top: 0;
        right: 0;
    }
}
@media (min-width: 320px) and (max-width: 575px) {
    .three-d-album-width {
        width: 100%;
        margin-top: 170px;
        padding: 0 15px;
    }
}
@media (min-width: 320px) and (max-width: 575px) {
    .three-d-album-width {
        margin-top: 100px;
    }
}
@media (min-width: 320px) and (max-width: 575px) {
    .threed-container-inner {
        -webkit-transform: translate3d(0, 0, 0) scale(1) rotateX(0deg) rotateY(0deg) rotateZ(0deg);
        transform: translate3d(0, 0, 0) scale(1) rotateX(0deg) rotateY(0deg) rotateZ(0deg);
    }
}
@media (min-width: 320px) and (max-width: 575px) {
    .single-3d {
        -ms-flex: 0 0 46%;
        -webkit-box-flex: 0;
        flex: 0 0 46%;
        max-width: 46%;
        overflow: hidden;
        padding: 0;
        margin: 5px;
    }
}
@media (min-width: 320px) and (max-width: 575px) {
    .empty-space {
        display: none;
    }
}
@media (min-width: 320px) and (max-width: 575px) {
    .single-3d {
        -ms-flex: 0 0 46%;
        -webkit-box-flex: 0;
        flex: 0 0 46%;
        max-width: 46%;
        overflow: hidden;
        padding: 0;
        margin: 5px;
    }
}