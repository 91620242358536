.ticket-chat-container {
  display: flex;
  background: #0c0c0c;
  color: #fff;
  padding: 20px;
  height: 90vh;
}

.ticket-info-panel {
  width: 25%;
  background: #0c0c0c ;
  padding: 15px;
  border-radius: 8px;
}

.ticket-info-panel h3 {
  margin-bottom: 10px;
}

.ticket-info-panel p {
  margin: 5px 0;
}

.status-open {
  color: #ff5722;
}

.status-in {
  color: #ff9800 !important;
  background: none !important;
}

.status-closed {
  color: #4caf50;
}

.reply-btn, .close-btn {
  display: block;
  width: 100%;
  margin: 5px 0;
  padding: 8px;
  border: none;
  cursor: pointer;
}

.reply-btn {
  background: #2196f3;
  color: white;
}

.close-btn {
  background: #f44336;
  color: white;
}

.chat-section {
  flex-grow: 1;
  margin-left: 20px;
}

.chat-messages {
  height: 55vh;
  overflow-y: auto;
  /* background: #25253a; */
  padding: 10px;
  border-radius: 5px;
}

.message {
  padding: 5px;
  border-radius: 5px;
  margin: 5px 0;
}

.message.sent {
  background: #2196f3;
  text-align: right;
}

.message.received {
  background: #4a4a6a;
  text-align: left;
}

.chat-input {
  margin-top: 15px;
  display: flex;
  flex-direction: column;
}

.chat-input textarea {
  width: 100%;
  /* height: 60px; */
  background: #0c0c0c;

  color: white;
  border: 1px solid #3c3737;
  padding: 10px;
  border-radius: 5px;
}

.chat-actions {
  display: flex;
  justify-content: space-between;
  flex-flow: row-reverse;
  margin-top: 5px;
}

.attach-btn, .send-btn {
  padding: 10px 15px;
  border: none;
  cursor: pointer;
  font-weight: bold;
}

.attach-btn {
  background: #4caf50;
  color: white;
}

.send-btn {
  background: #2196f3;
  color: white;
}


body {
  background-color: #121212;
  color: #fff;
  font-family: Arial, sans-serif;
  margin: 0;
  padding: 0;
}

.tickets-container,
.raise-ticket-container,
.chat-container {
  padding: 20px;
  max-width: 600px;
  margin: 20px auto;
  /* background-color: #1f1f1f; */
  border-radius: 10px;
}

.ticket-list {
  list-style: none;
  padding: 0;
}

.ticket-item {
  /* background-color: #292929; */
  padding: 15px;
  border-radius: 8px;
  margin-bottom: 10px;
  transition: background 0.3s ease;
}

.ticket-item:hover {
  background-color: #333;
}

.ticket-info h3 {
  margin: 0;
  color: #e91e63;
}

.chat-messages {
  max-height: 300px;
  overflow-y: auto;
  padding: 10px;
  background-color: #0c0c0c;
  border: 1px solid #3c3737;
  border-radius: 8px;
  margin-bottom: 10px;
}

.message {
  padding: 5px;
  margin: 5px 0;
  border-radius: 8px;
  max-width: 70%;
}

.sent {
  background-color: #4caf50;
  margin-left: auto;
  text-align: right;
}

.received {
  background-color: #333;
  margin-right: auto;
}

.chat-input {
  display: flex;
  gap: 10px;
}

.chat-input input {
  flex: 1;
  padding: 10px;
  border-radius: 8px;
  border: none;
  background-color: #333;
  color: #fff;
}

.chat-input button {
  padding: 10px 20px;
  border: none;
  background-color: #e91e63;
  color: #fff;
  border-radius: 8px;
  cursor: pointer;
}

form input,
form textarea {
  width: 100%;
  padding: 10px;
  margin-bottom: 15px;
  border-radius: 8px;
  border: none;
  background-color: #333;
  color: #fff;
}

form button {
  padding: 10px;
  background-color: #e91e63;
  color: #fff;
  border: none;
  border-radius: 8px;
  cursor: pointer;
}

.ticket-bg .bg-secondary{
  background-color: #333 !important;
}