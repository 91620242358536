.how_to .text-center {
  text-align: center !important;
}
.how_to .sk__heading-spacer-s {
  padding-bottom: 60px;
}
.how_to .cover-text-wrapper {
  position: relative;
  overflow: hidden;
}
.how_to .fancy-gradient-text-box {
  display: inline-block;
  position: relative;
}
@media (min-width: 576px) {
  .how_to .mb-sm-0 {
    margin-bottom: 0 !important;
  }
}
.how_to .sk__gradient-fancy-text,
.how_to .sk__gradient-fancy-text-back {
  -webkit-text-stroke: 6px transparent;
}
.how_to .sk__gradient-fancy-text,
.how_to
  a.sk__iconbox-icon-link:hover
  span[class*="icon-"].sk__gradient-fancy-text {
  background: rgb(129, 21, 255);
  background: -webkit-gradient(
    linear,
    left top,
    right top,
    from(rgba(129, 21, 255, 1)),
    color-stop(52%, rgba(255, 42, 208, 1)),
    to(rgba(255, 143, 42, 1))
  );
  background: -o-linear-gradient(
    left,
    rgba(129, 21, 255, 1) 0%,
    rgba(255, 42, 208, 1) 52%,
    rgba(255, 143, 42, 1) 100%
  );
  background: linear-gradient(
    90deg,
    rgba(129, 21, 255, 1) 10%,
    rgba(255, 42, 208, 1) 52%,
    rgba(255, 143, 42, 1) 100%
  );
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#8115ff",endColorstr="#ff8f2a",GradientType=1);
  -webkit-background-clip: text;
  color: #000000;
  padding: 3px;
  padding: 0.065em;
}
.how_to h1.super-heading,
.how_to span.super-heading.sk__gradient-fancy-text-back {
  /* font-family: "Poppins", sans-serif; */
  font-weight: 700;
  font-size: 102.75px;
  letter-spacing: -5.1px;
  text-transform: inherit;
  line-height: 1.35;
  margin-bottom: 0.3em;
}
@media (min-width: 1200px) and (max-width: 1399px) {
  .how_to h1.super-heading,
  .how_to span.super-heading.sk__gradient-fancy-text-back {
    font-size: 92px;
  }
}
.how_to .sk__gradient-fancy-text-back {
  position: absolute;
  top: 0;
  left: 0;
  width: auto;
  height: 100%;
  -webkit-transform: translate(5px, 5px);
  -ms-transform: translate(5px, 5px);
  transform: translate(7px, 7px);
  z-index: -1;
  background: rgb(129, 21, 255);
  background: -webkit-gradient(
    linear,
    left top,
    right top,
    from(rgba(129, 21, 255, 0.7035189075630253)),
    color-stop(52%, rgba(255, 42, 208, 0.4990371148459384)),
    to(rgba(255, 143, 42, 0.4009978991596639))
  );
  background: -o-linear-gradient(
    left,
    rgba(129, 21, 255, 0.7035189075630253) 0%,
    rgba(255, 42, 208, 0.4990371148459384) 52%,
    rgba(255, 143, 42, 0.4009978991596639) 100%
  );
  background: linear-gradient(
    90deg,
    rgba(129, 21, 255, 0.7035189075630253) 10%,
    rgba(255, 42, 208, 0.4990371148459384) 52%,
    rgba(255, 143, 42, 0.4009978991596639) 100%
  );
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#8115ff",endColorstr="#ff8f2a",GradientType=1);
  -webkit-background-clip: text;
  color: #000000;
  padding: 3px;
  padding: 0.065em;
}
.how_to h2,
.how_to h2.h2-regular,
.how_to span.h2-regular.sk__gradient-fancy-text-back {
  /* font-family: "Poppins", sans-serif; */
  font-size: 24px;
  line-height: 1.4;
  margin-bottom: 0.9em;
  letter-spacing: -0.24px;
  color: #ffffff;
}
.how_to h2.h2-large,
.how_to span.h2-large.sk__gradient-fancy-text-back {
  font-size: 33px;
  line-height: 1.5;
}

.text-center {
  text-align: center !important;
}
.sk__iconbox {
  padding: 20px;
  margin-bottom: 20px;
}
.sk__iconbox a h5{
    font-size: 24px;
    font-weight: 800;
}
.sk__iconbox p{
    color: #fff;
    /* font-size: 14px; */
}
a.sk__iconbox-icon-link {
  display: inline-block;
  margin: 0 auto 25px;
  position: relative;
  width: 128px;
  height: 128px;
  mix-blend-mode: difference;
  -webkit-transition: initial;
  -o-transition: initial;
  transition: initial;
}
@media (min-width: 576px) and (max-width: 1920px) {
  a.sk__iconbox-icon-link {
    margin: 0 auto 15px;
    width: 112px;
    height: 112px;
  }
}
.sk__flex-center,
.sk__hero-item-theme-style.sk__flex-center {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-flow: row wrap;
  flex-flow: row wrap;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}
a.sk__iconbox-icon-link i,
a.sk__iconbox-icon-link span[class^="icon-"],
a.sk__iconbox-icon-link > span.sk__iconbox-icon,
.sk__ease-06,
.sk__in-content-post-image,
.sk__imagebox-img {
  -webkit-transition: all 0.6s ease 0s;
  -o-transition: all 0.6s ease 0s;
  transition: all 0.6s ease 0s;
}
a.sk__iconbox-icon-link > span.sk__iconbox-icon {
  position: absolute;
  width: 128px;
  height: 128px;
  border: 2px solid rgba(255, 255, 255, 0.13);
  border-radius: 130px;
  overflow: hidden;
}
a.sk__iconbox-icon-link > span.sk__iconbox-icon{
    background:  radial-gradient(
        rgba(129, 21, 255, 0) 45%,
        rgba(129, 21, 255, 0.25253851540616246) 62%,
        rgba(255, 42, 208, 0.35898109243697474) 85%,
        rgba(255, 143, 42, 0.5158438375350141) 99%,
        rgba(255, 169, 17, 0.8239670868347339) 100%
      );
}
a.sk__iconbox-icon-link > span.sk__iconbox-icon svg{
    width: 50px;
    color: #fff;
    z-index: 999999999;
    height: 100%;
}
@media (min-width: 576px) and (max-width: 1920px) {
  a.sk__iconbox-icon-link > span.sk__iconbox-icon {
    width: 112px;
    height: 112px;
    border-radius: 114px;
  }
}
@media (min-width: 576px) and (max-width: 1920px) {
  a.sk__iconbox-icon-link i,
  a.sk__iconbox-icon-link span[class^="icon-"] {
    font-size: 41px;
  }
}
a.sk__iconbox-icon-link span[class*="icon-"].sk__gradient-fancy-text {
  z-index: 1;
  background: #000000;
  color: #ffffff;
  -webkit-background-clip: text;
  -webkit-text-stroke: 0 transparent;
}
.icon-diamond1:before {
  content: "\e9ef";
}
a.sk__iconbox-icon-link > span.sk__iconbox-icon:after {
  content: "";
  position: absolute;
  width: 128px;
  height: 128px;
  top: -1px;
  left: -1px;
  border: 2px solid transparent;
  -webkit-transform-origin: center;
  -ms-transform-origin: center;
  transform-origin: center;
  border-radius: 130px;
  background: #000000;
}
@media (min-width: 576px) and (max-width: 1920px) {
  a.sk__iconbox-icon-link > span.sk__iconbox-icon:after {
    width: 112px;
    height: 112px;
    border-radius: 114px;
  }
}
a.sk__iconbox-icon-link > span.sk__iconbox-icon > span.sk__iconbox-trail {
  content: "";
  opacity: 1;
  position: absolute;
  width: 124px;
  height: 124px;
  top: 1px;
  left: 1px;
  border-radius: 130px;
  -webkit-transform-origin: center bottom;
  -ms-transform-origin: center bottom;
  transform-origin: center bottom;
  -webkit-animation: rotateInfinitely 3s linear 0s infinite;
  animation: rotateInfinitely 3s linear 0s infinite;
}
@media (min-width: 576px) and (max-width: 1920px) {
  a.sk__iconbox-icon-link > span.sk__iconbox-icon > span.sk__iconbox-trail {
    width: 108px;
    height: 108px;
    border-radius: 114px;
  }
}
a.sk__iconbox-icon-link > span.sk__iconbox-icon > span.sk__iconbox-trail {
  background: rgb(129, 21, 255);
  background: -moz-linear-gradient(
    90deg,
    rgba(129, 21, 255, 0) 45%,
    rgba(129, 21, 255, 0.25253851540616246) 62%,
    rgba(255, 42, 208, 0.35898109243697474) 85%,
    rgba(255, 143, 42, 0.5158438375350141) 99%,
    rgba(255, 169, 17, 0.8239670868347339) 100%
  );
  background: -webkit-linear-gradient(
    90deg,
    rgba(129, 21, 255, 0) 45%,
    rgba(129, 21, 255, 0.25253851540616246) 62%,
    rgba(255, 42, 208, 0.35898109243697474) 85%,
    rgba(255, 143, 42, 0.5158438375350141) 99%,
    rgba(255, 169, 17, 0.8239670868347339) 100%
  );
  background: linear-gradient(
    90deg,
    rgba(129, 21, 255, 0) 45%,
    rgba(129, 21, 255, 0.25253851540616246) 62%,
    rgba(255, 42, 208, 0.35898109243697474) 85%,
    rgba(255, 143, 42, 0.5158438375350141) 99%,
    rgba(255, 169, 17, 0.8239670868347339) 100%
  );
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#8115ff",endColorstr="#ffa911",GradientType=1);
}
a.sk__iconbox-icon-link
  > span.sk__iconbox-icon:nth-child(2)
  > span.sk__iconbox-trail {
  -webkit-animation-delay: 1s;
  animation-delay: 1s;
}
span.sk__iconbox-icon-dash {
    opacity: 1;
    position: absolute;
    width: 90%;
    height: 90%;
    top: 5%;
    left: 5%;
    border-radius: 100%;
    border: 2px dotted #000;
    z-index: -1;
    -webkit-transition: all 0.4s ease 0s;
    -o-transition: all 0.4s ease 0s;
    transition: all 0.4s ease 0s;
    -webkit-animation: counterRotateInfinitely 30s linear 0s infinite;
    animation: counterRotateInfinitely 30s linear 0s infinite;
}
span.sk__iconbox-icon-dash {
    background: rgb(129, 21, 255);
    background: -webkit-gradient(linear, left top, left bottom, from(#000), to(#000)) padding-box, -webkit-gradient(linear, left top, right top, from(rgba(129, 21, 255, 1)), color-stop(50%, rgba(255, 42, 208, 1)), to(rgba(255, 143, 42, 1))) border-box;
    background: -o-linear-gradient(#000, #000) padding-box, -o-linear-gradient(left, rgba(129, 21, 255, 1) 0%, rgba(255, 42, 208, 1) 50%, rgba(255, 143, 42, 1) 100%) border-box;
    background: linear-gradient(#000, #000) padding-box, linear-gradient(90deg, rgba(129, 21, 255, 1) 0%, rgba(255, 42, 208, 1) 50%, rgba(255, 143, 42, 1) 100%) border-box;
}
.gradient-links-bright {
    background: rgb(255, 255, 255);
    background: -webkit-gradient(linear, left top, right top, from(rgba(255, 255, 255, 1)), color-stop(25%, rgba(255, 255, 255, 1)), color-stop(43%, rgba(129, 21, 255, 1)), color-stop(72%, rgba(255, 42, 208, 1)), to(rgba(255, 171, 0, 1)));
    background: -o-linear-gradient(left, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 1) 25%, rgba(129, 21, 255, 1) 43%, rgba(255, 42, 208, 1) 72%, rgba(255, 171, 0, 1) 100%);
    background: linear-gradient(90deg, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 1) 25%, rgba(129, 21, 255, 1) 43%, rgba(255, 42, 208, 1) 72%, rgba(255, 171, 0, 1) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#ffffff",endColorstr="#ffab00",GradientType=1);
}
.gradient-links, .gradient-links-bright {
    display: table;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-size: 400% 400%;
    background-position: 0% 100%;
}
a:visited {
    color: #FFFFFF;
}
a.sk__iconbox-text-link {
    -webkit-transition: initial;
    -o-transition: initial;
    transition: initial;
    margin: 0 auto;
}
