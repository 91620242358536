.section_section__I0u9H {
  padding: 100px 20px;
  background-size: cover;
  position: relative;
  align-items: center;
  display: flex;
}
.section_gradient__n5Qe_ {
  background: center 100%
      radial-gradient(ellipse at bottom, hsla(0, 0%, 8%, 0.8) 10% 10%, #141414),
    50% / 400% no-repeat
      linear-gradient(-45deg, #00ef86, #008cff, #ec3258, #fcda00);
  animation: section_gradient__n5Qe_ 15s ease infinite;
}
@media screen and (min-width: 768px) {
  .section_tall__wQ9qW {
    min-height: 70vh;
  }
}
@media screen and (min-width: 768px) {
  .section_alignCenter__x2Hse {
    text-align: center;
  }
}
.section_section__I0u9H:nth-child(odd) {
  background-color: #111;
}
.section_section__I0u9H:nth-child(odd) {
  background-color: #1c1e1f;
}
.section_section__I0u9H.section_header__XvyD1 {
  background: linear-gradient(transparent, #111),
    center 100%
      radial-gradient(ellipse at bottom, hsla(0, 0%, 8%, 0.8) 10% 10%, #141414),
    50% / 400% no-repeat
      linear-gradient(-45deg, #00ef86, #008cff, #ec3258, #fcda00);
  animation: section_gradient__n5Qe_ 15s ease infinite;
}
.content-record_backgroundImage__JW5D1 {
  position: absolute;
  inset: 0;
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
  width: 100%;
  pointer-events: none;
  background-size: cover;
  opacity: 30%;
  --media-object-fit: cover;
  --media-object-position: top center;
}
.content-record_backgroundImage__JW5D1 {
  mask-image: linear-gradient(#000, transparent);
  -webkit-mask-image: linear-gradient(#000, transparent);
}
.container_container__e7iG2 {
  max-width: 1200px;
  margin: auto;
  position: relative;
  width: 100%;
  align-items: center;
  row-gap: 40px;
  display: flex;
  column-gap: 100px;
}
.content_content__hiCNB {
  display: flex;
  flex-direction: column;
  row-gap: 20px;
  flex: 1;
  width: 100%;
}
.content_marginAuto__3jHEC {
  margin: auto;
}
.content_lg__0npCb {
  row-gap: 20px;
}
.content_content__hiCNB {
  display: flex;
  flex-direction: column;
  row-gap: 20px;
  flex: 1;
  width: 100%;
}
.content_marginAuto__3jHEC {
  margin: auto;
}
.content_lg__0npCb {
  row-gap: 20px;
}
mark {
  color: #fff;
  text-decoration: none;
  background: linear-gradient(-45deg, #00ef86, #008cff, #ec3258, #fcda00);
  background-size: 400% 400%;
  animation: gradient 15s ease infinite;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.button-container_buttonContainer__YRgZl {
  display: flex;
  flex-direction: row;
  -moz-column-gap: 10px;
  column-gap: 10px;
  row-gap: 10px;
  flex-wrap: wrap;
  align-items: center;
}
@media screen and (min-width: 768px) {
  .button-container_center__H3vVZ {
    justify-content: center;
  }

}
.button_button__MFmXH,
.button_button__MFmXH:active,
.button_button__MFmXH:link,
.button_button__MFmXH:visited {
  position: relative;
  padding: 12px 27px;
  font-size: 15px;
  border-radius: 10px;
  font-weight: 900;
  letter-spacing: 1px;
  text-transform: uppercase;
  color: #fff;
  transition: all 50ms ease;
  text-decoration: none;
  width: -moz-fit-content;
  width: fit-content;
  white-space: nowrap;
  align-items: center;
  z-index: 1;
  outline: 0;
  cursor: pointer;
  display: inline-flex;
  gap: 5px;
  justify-content: center;
}
.button_button__MFmXH.button_color__bLscP {
  padding: 14px 29px;
}
.button_button__MFmXH.button_color__bLscP,
.button_button__MFmXH:before {
  background-image: linear-gradient(-45deg, #00ef86, #008cff, #ec3258, #fcda00);
  background-size: 400% 400%;
  animation: button_gradient__4AUBK 15s ease infinite;
}

.button_button__MFmXH:before {
  position: absolute;
  inset: 0;
  content: "";
  z-index: -1;
  border-radius: 100px;
  filter: blur(15px) saturate(200%);
  opacity: 0;
  transition: all 0.15s ease;
}

.button_button__MFmXH:before {
  position: absolute;
  inset: 0;
  content: "";
  z-index: -1;
  border-radius: 100px;
  filter: blur(15px) saturate(200%);
  opacity: 0;
  transition: all 0.15s ease;
}
.content_marginAuto__3jHEC h1 {
  font-size: 63px;
  font-weight: 700;
  color: #fff;
}
@media screen and (min-width: 768px) {
  .section_filmGrain__cakaz:after,
  .section_header__XvyD1:after {
    display: block;
    content: " ";
    position: absolute;
    inset: 0;
    mix-blend-mode: lighten;
    pointer-events: none;
    background-image: url("https://www.tunecore.com/images/dirt.gif");
  }
}
@media screen and (min-width: 768px) {
  .content-record_large__sQtlg {
    font-size: 1.3rem;
    line-height: 1.5;
    color: #a1a1a1;
  }
}
@keyframes section_gradient__n5Qe_ {
  0% {
    background-position: 0 50%;
  }

  20% {
    background-position: 20% 50%;
  }
  40% {
    background-position: 40% 50%;
  }
}
@keyframes gradient {
  0% {
    background-position: 0 50%;
  }

  20% {
    background-position: 20% 50%;
  }
  40% {
    background-position: 40% 50%;
  }
  60% {
    background-position: 60% 50%;
  }
  80% {
    background-position: 80% 50%;
  }
  100% {
    background-position: 0 50%;
  }
}

@keyframes button_gradient__4AUBK {
  0% {
    background-position: 0 50%;
  }

  20% {
    background-position: 20% 50%;
  }
  40% {
    background-position: 40% 50%;
  }
  60% {
    background-position: 60% 50%;
  }
  80% {
    background-position: 80% 50%;
  }
  100% {
    background-position: 0 50%;
  }
}

/* Hero Two    */
.content_lg__0npCb h2 {
  font-size: 55px;
  font-weight: 700;
  color: #fff;
}
.store-icons_storeIcons__j7j8r {
  padding: 30px 0;
  display: flex;
  justify-content: center;
  align-items: center;
  column-gap: 70px;
  row-gap: 30px;
  flex-wrap: wrap;
}
.store-icons_storeIcons__j7j8r a,
.store-icons_storeIcons__j7j8r a:active,
.store-icons_storeIcons__j7j8r a:link,
.store-icons_storeIcons__j7j8r a:visited {
  color: #fff;
  text-decoration: underline;
}
.store-icons_icon__WvvWP {
  width: 130px;
  height: 50px;
  -o-object-fit: contain;
  object-fit: contain;
}
@media (max-width: 598.5px) {
  .content_marginAuto__3jHEC h1 {
    font-size: 50px;
  }
  .content-record_large__sQtlg{
color: #a1a1a1;
  }
  .button-container_buttonContainer__YRgZl {
    align-items: start  ;
  }
}



