.signin_page .rnb-flex {
  display: flex;
}
.signin_page .rnb-flex-row {
  flex-direction: row;
}
.signin_page .rnb-justify-between {
  justify-content: space-between;
}
.signin_page .rnb_sign_in_banner {
  background-image: linear-gradient(
      60deg,
      #2531c0a1 0%,
      #f7bfbfa1 40%,
      rgba(251, 127, 119, 0.63) 63%,
      #1a08c4a1 100%
    ),
    url(https://www.routenote.com/rn/_assets/sign-in/sign-in-lights-hero.png),
    url(https://www.routenote.com/rn/_assets/sign-in/sign-in-hero.jpg);
  background-size: cover;
  background-position: bottom;
  background-repeat: no-repeat;
  /* width: 611px; */
}
@media (max-width: 1599px) {
  .signin_page .rnb_sign_in_banner {
    /* width: 510px; */
  }
}
.signin_page .rnb-fixed {
  /* position: fixed; */
}
.signin_page .rnb-h-screen {
  /* height: 100vh; */
}
.signin_page .rnb_sign_in_login {
  z-index: 9999;
  margin-top: 23px;
  margin-right: 60px;
}
/* .rnb-absolute {
  position: absolute;
} */
.signin_page .rnb-right-0 {
  right: 0px;
}
.signin_page .rnb-flex-row {
  flex-direction: row;
}
.signin_page .rnb-items-center {
  align-items: center;
}
.signin_page .rnb-justify-between {
  justify-content: space-between;
}
.signin_page .rnb_p_lb {
  font-size: 16px;
  line-height: 28px;
}
.signin_page .rnb_sign_in_login .rnb_p_lb {
  margin-right: 20px;
}
.signin_page .rnb_a,
.signin_page .rnb_button,
.signin_page .hooper-navigation button,
.signin_page .rnb_a *,
.signin_page .rnb_button *,
.signin_page .hooper-navigation button * {
  text-decoration: none !important;
  -webkit-user-select: none !important;
  -moz-user-select: none !important;
  -ms-user-select: none !important;
  user-select: none !important;
  cursor: pointer !important;
  transition: all 0.1s ease;
  border: none !important;
  -webkit-tap-highlight-color: transparent !important;
}
.signin_page .rnb_move_up,
.signin_page .rnb_move_up * {
  display: inline-table !important;
}
.signin_page .rnb_h6 {
  font-size: 16px;
  line-height: 24px;
}
.signin_page .rnb_move_up,
.signin_page .rnb_move_up * {
  display: inline-table !important;
}
.signin_page .rnb_text_blue,
.signin_page .rnb_border_button .rnb_rectangle_button,
.signin_page .rnb_border_button .rnb_rounded_button,
.signin_page .rnb_white_button .rnb_rectangle_button,
.signin_page .rnb_white_button .rnb_rounded_button {
  color: #fff;
}
.signin_page .rnb_border_blue,
.signin_page .rnb_border_button .rnb_rectangle_button,
.signin_page .rnb_border_button .rnb_rounded_button {
  border: 2px solid #1345ac !important;
}
.signin_page .rnb_a .rnb_rectangle_button,
.signin_page .rnb_a .rnb_rounded_button,
.signin_page .rnb_rounded_button.rnb_a {
  padding: 13px 32px;
  height: auto;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  flex-shrink: 0;
  white-space: nowrap;
}
.signin_page .rnb_border_button .rnb_rectangle_button,
.signin_page .rnb_border_button .rnb_rounded_button {
  --tw-bg-opacity: 1;
  background-color: rgba(255, 255, 255, 1);
}
.signin_page .rnb_sign_in_inner {
  padding: 20px 20px 20px 20px;
  max-width: 650px;
}
.signin_page .rnb-mx-auto {
  margin-left: auto;
  margin-right: auto;
}
.signin_page .rnb-flex-1 {
  flex: 1 1 0%;
}
.signin_page .rnb-text-center {
  text-align: center;
}
.signin_page .rnb-items-end {
  align-items: flex-end;
}
.signin_page .rnb-flex-col {
  flex-direction: column;
}
.signin_page .rnb_margin_top_md {
  margin-top: 40px;
}
.signin_page .rnb-justify-start {
  justify-content: flex-start;
}
.signin_page .rnb-w-full {
  width: 100%;
}
.signin_page .rnb_h7 {
  font-size: 14px;
  line-height: 22px;
}
.signin_page .rnb_input {
  max-width: 441px;
  width: 100%;
  border: none;
  padding: 16px !important;
  line-height: 16px !important;
  border-radius: 15px;
}
.signin_page .rnb_p_lb {
  font-size: 16px;
  line-height: 28px;
}
.signin_page .rnb_text_darker_grey {
  color: #202020;
}
.signin_page .rnb_bg_grey {
  background-color: #f5f5f5;
}
.signin_page .rnb_margin_top_xs {
  margin-top: 10px;
}
.signin_page .rnb_input {
  box-sizing: content-box;
}
.signin_page .rnb_sign_in_inner .rnb_input {
  max-width: calc(100% - 32px);
}
.signin_page .rnb_sign_in_form .rnb_a.rnb_blue_button {
  margin: 0px;
}
.signin_page .rnb_move_up,
.signin_page .rnb_move_up * {
  display: inline-table !important;
}
.signin_page .rnb_a .rnb_rectangle_button,
.signin_page .rnb_a .rnb_rounded_button,
.signin_page .rnb_rounded_button.rnb_a {
  padding: 13px 32px;
  height: auto;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  flex-shrink: 0;
  white-space: nowrap;
}
.signin_page .rnb_blue_button .rnb_rectangle_button,
.signin_page .rnb_blue_button .rnb_rounded_button {
  --tw-text-opacity: 1;
  color: rgba(255, 255, 255, 1);
}
.signin_page .rnb-flex.rnb-flex-row.rnb-justify-between {
  color: #fff;
}
.signin_page .sign_in_image {
  min-height: 100vh;
  width: 100%;
  height: calc(100vh + 60px);
}
@media (max-width: 768px) {
  .signin_page .sign_in_image {
    display: none;
  }
  .form_card{
    padding-inline: 0px;
    margin-inline: 10px !important;
  }
}
.form_card{
  /* border: 2px solid violet; */
  background: #ffffff17 ;
  border-radius: 40px;
  padding: 30px;
}
