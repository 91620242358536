.aIYhp.aIYhp {
    margin-top: 48px;
    gap: 36px;
    -webkit-box-pack: center;
    justify-content: center;
    flex-direction: row;
}
.ooTXc {
    display: flex;
}
.eFNxYy {
    align-self: start;
    width: 376px;
    border-radius: 8px;
    background: radial-gradient(100% 50% at 100% 0.1%, rgb(44, 60, 77) 0%, rgb(20, 39, 57) 100%);
    position: relative;
    top: inherit;
    margin-bottom: inherit;
}
.fVuaPK {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 36px;
    gap: 20px;
    position: relative;
}
.ktbboT {
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    gap: 12px;
    align-self: stretch;
    color: rgb(249, 250, 251);
    font-size: 1.125rem;
    font-weight: 700;
    line-height: 32px;
    letter-spacing: -0.4px;
}
.choEMT {
    display: flex;
    -webkit-box-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    align-items: center;
    border-radius: 8px;
    width: 36px;
    min-width: 36px;
    height: 36px;
    font-size: 20px;
    color: rgb(116, 255, 180);
    background: rgba(29, 182, 145, 0.32);
}
.qDzkx {
    color: inherit;
    font-size: 1.5rem;
    line-height: 36px;
}
.hnwYCT {
    flex-direction: column;
    align-items: flex-start;
    align-self: stretch;
    gap: 4px;
}
.iVhLIe.iVhLIe {
    height: 20px;
}
.fSwyMM {
    display: grid;
    grid-template:
        "currency price decimal vat" min-content
        "currency price commitment commitment" min-content / auto auto auto 1fr;
    column-gap: 4px;
}
.jcwSe {
    grid-area: currency;
    color: rgb(249, 250, 251);
    font-weight: 400;
    line-height: 24px;
    letter-spacing: -0.2px;
    font-size: 0.9375rem;
}
.iNncwz {
    grid-area: price;
    color: rgb(249, 250, 251);
    font-weight: 700;
    line-height: 48px;
    letter-spacing: -0.8px;
    font-size: 3rem;
}
.kdpxmo {
    grid-area: decimal;
    color: rgb(249, 250, 251);
    font-weight: 400;
    line-height: 24px;
    letter-spacing: -0.2px;
    font-size: 0.9375rem;
}
.gmA-dwh {
    grid-area: vat;
    color: rgb(249, 250, 251);
    font-size: 0.9375rem;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: -0.2px;
}
.eNMDqz {
    grid-area: commitment;
    display: flex;
    flex-direction: row;
}
.bcCPui {
    color: rgb(173, 183, 191);
    font-weight: 400;
    line-height: 24px;
    letter-spacing: -0.2px;
    font-size: 0.9375rem;
}
.iXJPXS {
    color: #fff;
    font-size: 0.9375rem;
    line-height: 24px;
}
.dhKRaV.dhKRaV {
    margin-right: 4px;
}
.jXJGze {
    color: rgb(249, 250, 251);
    font-weight: 400;
    line-height: 24px;
    letter-spacing: -0.2px;
    font-size: 0.9375rem;
}

.fqCcLR {
    display: flex;
    flex-direction: column;
    margin: 0px;
}

.rORuB {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    align-self: stretch;
    gap: 8px;
    letter-spacing: -0.4px;
}
.eWBBOj {
    display: flex;
    flex-direction: row;
    gap: 8px;
    text-align: left;
    align-items: flex-start;
}
.hnRKae.hnRKae {
    padding-top: 2px;
}
.ebBhgj.ebBhgj {
    width: 20px;
    height: 20px;
}
[data-product="distribution"] .sc-1ulpv50-0 {
    color: rgb(116, 255, 180);
}
.ipZUJs.ipZUJs {
    padding-top: 0px;
    color: #fff;
}
.bbfBzD {
    appearance: none;
    display: flex;
    flex-direction: row;
    -webkit-box-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    align-items: center;
    position: relative;
    width: 100%;
    border: none;
    text-align: center;
    vertical-align: middle;
    white-space: nowrap;
    font-weight: 700;
    padding: 0px 16px;
    text-decoration: none;
    user-select: none;
    transition: 300ms ease-in-out;
    border-radius: 100rem;
    font-size: 0.9375rem;
    line-height: 1.6;
    height: 48px;
    min-width: 48px;
    color: rgb(20, 39, 57);
    background-color: rgb(50, 203, 203);
}
.bbfBzD .ef8j7g-0 {
    display: flex;
    transition: opacity 300ms ease-in-out;
    opacity: 1;
}
.eYrpxF {
    align-self: start;
    width: 376px;
    border-radius: 8px;
    background: radial-gradient(100% 50% at 100% 0.1%, rgb(44, 60, 77) 0%, rgb(20, 39, 57) 100%);
    position: relative;
    top: -24px;
    margin-bottom: -24px;
}
.ixpTqh {
    -webkit-box-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    align-items: center;
    color: rgb(132, 223, 223);
    background-color: rgba(50, 203, 203, 0.16);
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    height: 24px;
    text-transform: uppercase;
    font-size: 11px;
    font-weight: 700;
}
.vgrHw {
    color: rgb(249, 250, 251);
    font-size: 2.25rem;
    font-style: normal;
    font-weight: 700;
    line-height: 40px;
    letter-spacing: -0.4px;
}
.fqCcLR > :not(:last-child) {
    margin-bottom: 8px;
}