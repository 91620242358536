/* TargetAudience.css */
#targetaudience.section {
  /* Assuming applyCardStyles applies some reusable card styles */
  color: white;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

#targetaudience .title-container {
  margin-bottom: 1rem;
}

#targetaudience .title-container .title {
  display: flex;
  justify-content: space-between;
}

#targetaudience .title-container .title svg {
  color: var(--primary-color);
  font-size: 1.3rem;
  cursor: pointer;
}

#targetaudience .chart {
  height: 13rem;
}

#targetaudience .chart .recharts-default-tooltip {
  background-color: var(--dark-background-color) !important;
  border: none !important;
  border-radius: 1rem;
  box-shadow: 0 0 60px 8px var(--primary-color);
}
