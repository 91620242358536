.three-d-album {
    padding: 55px 0 0;
    overflow: hidden;
}
.three-d-album {
    background-image: url("https://themeim.com/demo/milando/media/background/play_bg.png");
    background-position: left 93%;
    background-repeat: no-repeat;
    background-size: auto;
}
.section-title {
    margin-bottom: 50px;
}
.section-title.style-four {
    width: 100%;
    text-align: center;
}
.section-title h2 {
    font-size: 40px;
    line-height: 26px;
    color: #444;
    font-weight: 900;
    text-transform: uppercase;
    margin-bottom: 23px;
    padding-bottom: 25px;
    position: relative;
    display: inline-block;
}
.section-title.style-four h2 {
    color: #f60038;
    font-weight: 700;
}
.section-title h2:before, .section-title h2:after {
    position: absolute;
    content: '';
    bottom: 0;
    background: #e43a90;
    left: 50%;
    -webkit-transform: translateX(-50%);
    -ms-transform: translateX(-50%);
    transform: translateX(-50%);
}
.section-title h2:before {
    width: 200px;
    height: 1px;
}
.section-title.style-four h2::before {
    width: 70px;
    height: 1px;
    background: #f60038;
}
.section-title h2:before, .section-title h2:after {
    position: absolute;
    content: '';
    bottom: 0;
    background: #e43a90;
    left: 50%;
    -webkit-transform: translateX(-50%);
    -ms-transform: translateX(-50%);
    transform: translateX(-50%);
}
.section-title h2:after {
    height: 3px;
    bottom: -1px;
    width: 30px;
    left: 50%;
}
.section-title.style-four h2::after {
    background: #f60038;
}
.section-title p {
    color: #a1a1a1;
    
    font-size: 16px;
    margin: 0;
}
.section-title.style-four p {
    padding: 0px 28%;
    font-size: 18px;
}
.three-d-album .more-album {
    position: absolute;
    right: 350px;
    margin-top: 54px;
}
.three-d-album .more-album a {
    font-size: 14px;
    text-decoration: none;
    color: #959595;
   
    font-weight: 500;
}
.three-d-album .more-album a i {
    color: #f60038;
    display: block;
    -webkit-transition: all 0.8s cubic-bezier(0, 1.09, 1, 1);
    -o-transition: all 0.8s cubic-bezier(0, 1.09, 1, 1);
    transition: all 0.8s cubic-bezier(0, 1.09, 1, 1);
    margin-left: 0;
}
.fa-play:before {
    content: "\f04b";
}
.three-d-album-width {
    width: 122%;
    margin-top: 300px;
}

.threed-container-wrapper {
    -webkit-perspective: 2950px;
    perspective: 2950px;
}
.threed-container-inner {
    -webkit-transform: translate3d(-11%, -25%, 0) scale(1) rotateX(45deg) rotateY(-5deg) rotateZ(25deg);
    transform: translate3d(-11%, -25%, 0) scale(1) rotateX(45deg) rotateY(-5deg) rotateZ(25deg);
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
}
.single-3d {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 16%;
    flex: 0 0 16%;
    max-width: 16%;
    padding: 15px;
}
.single-3d {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 16%;
    flex: 0 0 16%;
    max-width: 16%;
    padding: 15px;
}
.single-3d img {
    -webkit-box-shadow: 0px 36px 36.9px 1.1px rgba(0, 0, 0, 0.22), inset -0.5px -0.9px 0 0 rgba(255, 255, 255, 0.75);
    box-shadow: 0px 36px 36.9px 1.1px rgba(0, 0, 0, 0.22), inset -0.5px -0.9px 0 0 rgba(255, 255, 255, 0.75);
    -webkit-transition: all 0.8s cubic-bezier(0, 1.09, 1, 1);
    -o-transition: all 0.8s cubic-bezier(0, 1.09, 1, 1);
    transition: all 0.8s cubic-bezier(0, 1.09, 1, 1);
    z-index: 1;
    position: relative;
}
.single-3d:hover img {
    -webkit-transform: scale(1.5);
    -ms-transform: scale(1.5);
    transform: scale(1.5);
    -webkit-box-shadow: 0px 36px 67.9px 2.1px rgba(0, 0, 0, 0.5), inset -0.5px -0.9px 0 0 rgba(255, 255, 255, 0.75);
    box-shadow: 0px 36px 67.9px 2.1px rgba(0, 0, 0, 0.5), inset -0.5px -0.9px 0 0 rgba(255, 255, 255, 0.75);
    -webkit-transition: all .6s ease-in-out;
    -o-transition: all .6s ease-in-out;
    transition: all .6s ease-in-out;
    position: relative;
    z-index: 5;
}
img {
    max-width: 100%;
}

/* Speciality Section  */
.tf-section{
    padding: 50px 0px;
    background: #000000;
}
.tg-section-div-one{
    display: inline-table;
    min-height: 350px;
    width: 100%;
    max-width: 260px;
    cursor: pointer;
    border-radius: 50px;
    padding: 60px 43px 36px 43px;
    /* background-color: #111; */
}
.tg-section-div-one img{
    margin-bottom: 36px;
    width: 90px;
}
.tg-section-a-one{
    font-size: 21px !important;
    font-weight: 700 !important;
    line-height: 1.2;
    text-decoration: none;
    margin-bottom: 8px;
}
.tg-section-p-one{
    line-height: 1.7;
    color: #fff;
    margin-top: 14px;
    margin-bottom: 16px;
}
.tg-section-div-two{
    margin-top: 70px;
}






.banner-offer-cont{
    width: 100%;
    text-align: center;
    background-color: #d60d57;
    color: #fff;
    padding: 10px 0px;
    font-size: 15px;
}
.banner-offer-ancher{
    color: blue !important;
    margin-left: 5px;
}

/* Keep hundred Css  */
.keep-hundred-container{
    background-color: #000  ;
}
.w-layout-cell-keep{
    /* text-align: center; */
    padding: 70px;
    color: #fff;
}
.w-layout-cell-keep .heading {
    color: #fff;
    text-align: left;
    justify-content: center;
    max-width: none;
    margin-bottom: 0;
    margin-left: 0;
    margin-right: auto;
    padding-top: 20px;
    padding-bottom: 20px;
    padding-left: 0;
    /* font-family: Poppins, sans-serif; */
    font-size: 48px;
    font-weight: 500;
    line-height: 56px;
    display: block;
}
.w-layout-cell-keep p{
color:#b3b3b3;
margin-top: 0;
margin-bottom: 0;
padding-top: 8px;
padding-bottom: 8px;
/* font-family: Averta, Arial, sans-serif; */
font-size: 17px;
font-weight: 400;
line-height: 32px;
}


/* Reach Css  */
.section_section__I0u9H {
    padding: 100px 20px;
    background-size: cover;
    position: relative;
    align-items: center;
    display: flex;
}
.section_section__I0u9H:nth-child(odd) {
    background-color: #000;
}
.container_container__e7iG2 {
    max-width: 1200px;
    margin: auto;
    position: relative;
    width: 100%;
    align-items: center;
    row-gap: 40px;
    display: flex;
    -moz-column-gap: 100px;
    column-gap: 100px;
}
.container_right__z3b1d {
    flex-direction: row;
}
.content_content__hiCNB {
    display: flex;
    flex-direction: column;
    row-gap: 20px;
    flex: 1;
    width: 100%;
}
.content_marginAuto__3jHEC {
    margin: auto;
}
.content_lg__0npCb {
    row-gap: 20px;
}
.content_marginAuto__3jHEC h2{
    font-size: 50px;
    letter-spacing: -.5px;
    font-weight: 900;
    line-height: 1;
    color: #fff;
}
.content_marginAuto__3jHEC h3{
    font-weight: 700;
    font-size: 20px;
    line-height: 1.1;
    color: #fff
}
.content_marginAuto__3jHEC p{
    color: #a1a1a1;
    font-size: 14px;
    line-height: 1.5;
    margin-bottom: 0px !important;
}
/* .button-container_buttonContainer__YRgZl {
    display: flex;
    flex-direction: row;
    -moz-column-gap: 10px;
    column-gap: 10px;
    row-gap: 10px;
    flex-wrap: wrap;
    align-items: center;
} */
.keep-hundred-container{
    position: relative;
}
.w-layout-cell-keep:before{
    content: "";
    position: absolute;
    top: 50%;
    left: 50%;
    width: 35%;
    height: 35%;
    -webkit-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    border-radius: 50%;
    background: var(--tj-theme-primary);
    background: -o-linear-gradient(190deg, #8750f7 0%, rgba(115, 67, 210, 0) 100%);
    background: linear-gradient(260deg, #8750f7 0%, rgba(115, 67, 210, 0) 100%);
    -webkit-filter: blur(150px);
    filter: blur(150px);
}
/* .rs_inner_block:before{
    content: "";
    position: absolute;
    left: 25%;
    top: 10%;
    width: 40%;
    height: 30%;
    border-radius: 50%;
    background: var(--tj-theme-primary);
    background: -o-linear-gradient(190deg, #8750f7 0%, rgba(115, 67, 210, 0) 100%);
    background: linear-gradient(260deg,#8750f7 0%, rgba(115, 67, 210, 0) 100%);
    -webkit-filter: blur(150px);
    filter: blur(150px);
} */

.media_default__Mq0yp {
    position: relative;
    flex: 0 0 25%;
}
.media_medium__yJzWA {
    flex: 0 0 40%;
    width: 40%;
    margin: auto;
}
@media screen and (max-width: 768px) {
    .media_large__PVaav, .media_medium__yJzWA, .media_small__G9O7M {
        flex: 0 0 100%;
        width: 100%;
        margin: auto;
    }
}
.media_image__CtmQY {
    margin: auto;
    width: 100%;
    height: auto;
}
@media screen and (max-width: 768px) {
    .media_image__CtmQY {
        max-height: 400px;
        -o-object-fit: contain;
        object-fit: contain;
    }
}
@media (max-width:768px) {
    .tg-section-div-one {
        display: inline-table;
        min-height: 270px;
        width: 100%;
        max-width: 360px;
        cursor: pointer;
        border-radius: 50px;
        padding: 60px 43px 36px 43px;
        /* background-color: #111; */
    }
    .tg-section-div-two{
      margin-top: 20px;
    }
    .tg-section-div-there{
        margin-top: 20px;
    }
   
}





@media screen and (max-width: 768px) {
    .section_section__I0u9H {
        padding: 40px 20px;
    }
}
@media screen and (max-width: 768px) {
    .container_container__e7iG2 {
        flex-direction: column;
    }
}
@media screen and (max-width: 768px) {
    .container_top__tLiv0 {
        flex-direction: column-reverse;
    }
}
@media screen and (max-width: 768px) {
    .content_content__hiCNB.content_lg__0npCb {
        row-gap: 15px;
    }
}
@media screen and (max-width: 768px) {
    .content_content__hiCNB.content_lg__0npCb {
        row-gap: 15px;
    }
}
@media screen and (max-width: 400px) {
    .button-container_buttonContainer__YRgZl {
        flex-direction: column;
    }
}
@media screen and (max-width: 768px) {
    .section_section__I0u9H .button_button__MFmXH{
        width: 100%;
        text-align: center;
    }
    .banner-offer-cont{
        font-size: 12px;
    }
}

@media (max-width:448px){
    .content_marginAuto__3jHEC h2{
        font-size: 34px;
    }
}

