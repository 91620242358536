.z-index-step1 {
  position: relative;
  z-index: 41 !important;
}
.bg-dark {
  background-color: #0b0c10 !important;
}
.position-relative {
  position: relative !important;
}
.px-60 {
  padding-right: 60px;
  padding-left: 60px;
}
.py-40 {
  padding-top: 40px;
  padding-bottom: 40px;
}
.sub-title2 {
  background-image: linear-gradient(-45deg, #00ef86, #008cff, #ec3258, #fcda00);
  animation: button_gradient__4AUBK 15s ease infinite;
  display: inline-block;
  color: #fff;
  text-transform: uppercase;
  letter-spacing: 0.2em;
  font-weight: 700;
  padding: 2px 23px;
  margin-bottom: 10px;
}
.text-white {
  color: #fff !important;
}
.newsletter-style2 .form-control,
.newsletter-style1 .form-control {
  width: 100%;
  height: 50px;
  border: none;
  font-size: 14px;
  margin-right: -10px;
  color: #7c7e90;
  background-color: #ffffff;
  border-radius: 0;
  padding: 0 30px;
  padding-right: 60p;
}
.vs-btn {
  vertical-align: middle;
  display: inline-block;
  text-align: center;
  text-transform: uppercase;
  border: none;
  position: relative;
  overflow: hidden;
  background-color: transparent;
  z-index: 1;
  min-width: 210px;
  padding: 0;
  line-height: 1;
  transition: all ease 0.4s;
}
.vs-btn.black-skew,
.vs-btn.gradient-btn {
  color: #fff;
  padding: 18px 38px;
  font-size: 14px;
}
.newsletter-style2 .gradient-btn,
.newsletter-style1 .gradient-btn {
  margin-left: -18px;
}
.vs-btn:before {
  content: "";
  z-index: -1;
  position: absolute;
  left: 5.5px;
  right: 5.5px;
  top: 0;
  bottom: 0;
}
.vs-btn.black-skew:before,
.vs-btn.gradient-btn:before {
  transform: skewX(-10deg);
  background-image: linear-gradient(-45deg, #00ef86, #008cff, #ec3258, #fcda00);
  animation: button_gradient__4AUBK 15s ease infinite;
}
.vs-btn:after {
  content: "";
  position: absolute;
  top: -5%;
  left: -30%;
  width: 20px;
  height: 110%;
  opacity: 0.7;
  visibility: visible;
  background-color: #fff;
  transition: all ease 0.6s;
}
.vs-btn.black-skew:after,
.vs-btn.outline1:after,
.vs-btn.gradient-btn:after {
  transform: skewX(-10deg);
}
.bg-fluid {
  background-repeat: no-repeat;
  background-size: 100% 100%;
  background-position: center center;
}
.bg-major-black {
  /* background-color: #171920; */
  background-color: #0c0c0c;
}
.footer-layout1 .bg-fluid {
  background-repeat: no-repeat;
  background-size: auto 100%;
  background-position: right center;
}
.z-index-common {
  position: relative;
  z-index: 3;
}
.dark-style1,
.dark-style1 *:not([class*="text"]) {
  color: #fff;
}
.footer-layout1 .footer-widget-wrapper {
  padding-top: 185px;
  padding-bottom: 70px;
}
.widget {
  margin-bottom: 30px;
}
.footer-widget {
  padding: 0;
  border: none;
  padding-bottom: 0;
  background-color: transparent;
}
.widget_title {
  font-size: 20px;
  line-height: 1em;
  margin-bottom: 30px;
}
.footer-widget .widget_title {
  margin-bottom: 30px;
  padding-bottom: 20px;
  position: relative;
  letter-spacing: 0.03em;
}
.dark-style1 .widget_title,
.dark-style1 .icon-btn1 i {
  color: #fff;
}
.dark-style1,
.dark-style1 *:not([class*="text"]) {
  color: #fff;
}
.footer-widget .widget_title:before {
  content: "";
  position: absolute;
  left: 0;
  bottom: 0;
  width: 50px;
  height: 3px;
  border-radius: 4px;
  background-image: linear-gradient(-45deg, #00ef86, #008cff, #ec3258, #fcda00);
}
.vs-widget-about .about-text {
  font-size: 16px;
  line-height: 32px;
  color: #fff;
}
.mt-45 {
  margin-top: 45px;
}
.icon-btn {
  border: 1px solid #4b1587;
  /* display: inline-block; */
  padding: 10px;
  font-size: 14px;
  vertical-align: middle;
  color: #fff;
  background-image: linear-gradient(-45deg, #00ef86, #008cff, #ec3258, #fcda00);
  animation: button_gradient__4AUBK 15s ease infinite;
  text-align: center;
  width: 42px;
  height: 42px;
  line-height: 42px;
  border-radius: 50%;
  position: relative;
  transition: all 0.5s ease;
}
.widget_categories ul {
  list-style: none;
  margin: 0;
  padding: 0;
}
.widget_categories li {
  display: block;
  position: relative;
}
.widget_categories a {
  display: block;
  background-color: #fff;
  padding: 12px 20px;
  margin-bottom: 10px;
  color: #7c7e90;
  font-weight: 500;
  text-decoration: none;
  padding-right: 50px;
}
.widget.widget_categories a,
.footer-widget.widget_nav_menu a {
  background-color: transparent;
  padding: 0;
  position: relative;
  margin-bottom: 15px;
  font-weight: 500;
  display: inline-block;
}
/* .footer-widget.widget_categories a:before, .footer-widget.widget_nav_menu a:before {
    content: "\f105";
    font-family: Font Awesome 5 Pro;
    position: relative;
    top: 1px;
    margin-right: 10px;
    color: #9c55eb;
} */
.footer-widget.widget_nav_menu {
  margin-bottom: 3px;
  padding-bottom: 0;
}
.widget_nav_menu ul,
.widget_meta ul,
.widget_pages ul,
.widget_archive ul,
.widget_categories ul {
  list-style: none;
  margin: 0;
  padding: 0;
}
.widget_nav_menu li,
.widget_meta li,
.widget_pages li,
.widget_archive li,
.widget_categories li {
  display: block;
  position: relative;
}
.footer-widget.widget_meta a,
.footer-widget.widget_pages a,
.footer-widget.widget_archive a,
.footer-widget.widget_categories a,
.footer-widget.widget_nav_menu a {
  background-color: transparent;
  padding: 0;
  position: relative;
  text-decoration: none;
  margin-bottom: 15px;
  display: inline-block;
}
.contact-info {
  padding-left: 10px;
  position: relative;
  text-decoration: none !important;
  line-height: 20px;
}
.copywrite-text {
  color: #fff;
  margin: 0;
}
.copywrite-text a {
  color: #fff;
  text-decoration: none;
  font-weight: 700;
}
.footer-menu li {
  display: inline-block;
}
.footer-menu a {
  display: inline-block;
  font-size: 16px;
  text-transform: capitalize;
  color: #fff;
  font-weight: 500;
  padding: 38px 15px;
  line-height: 1;
  background-color: transparent;
  position: relative;
  text-decoration: none;
  outline: 0;
  transition: all ease 0.4s;
}
.footer-menu a:after {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  background-image: linear-gradient(to right, #4b1587 0%, #9c55eb 100%);
  height: 4px;
  width: 0;
  transition: all ease 0.4s;
}
.footer-menu ul {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

@keyframes button_gradient__4AUBK {
  0% {
    background-position: 0 50%;
  }
  20% {
    background-position: 20% 50%;
  }
  40% {
    background-position: 40% 50%;
  }
  60% {
    background-position: 60% 50%;
  }
  80% {
    background-position: 80% 50%;
  }
  100% {
    background-position: 0 50%;
  }
}

.footer-contact-us {
  display: flex;
  padding: 10px 0px;
}
/* .fa-map-marker-alt{
    width: 65px;
} */
@media (max-width: 1199px) {
  .vs-newsletter-wrapper .position-absolute {
    position: static !important;
    transform: none !important;
  }
}
@media (max-width: 768px) {
  .vs-newsletter-wrapper .py-40 {
    padding-top: 20px;
    padding-bottom: 20px;
  }
}
@media (max-width: 768px) {
  .vs-newsletter-wrapper .px-60 {
    padding-left: 10px;
    padding-right: 10px;
  }
}
@media (max-width: 768px) {
  .sub-title2 {
    padding: 2px 18px;
    letter-spacing: 0;
  }
}
@media (max-width: 768px) {
  .newsletter-style1 {
    text-align: center;
  }
  .footer-layout1 .footer-widget-wrapper {
    padding-top: 35px;
  }
}
@media (max-width: 768px) {
  .newsletter-style1 .gradient-btn {
    margin-top: 10px;
    margin-left: 0;
  }
  .widget.widget_categories a, .footer-widget.widget_nav_menu a{
    font-size: 14px;
  }
}
