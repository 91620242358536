/* .contentWrapper {
  display: flex;
  justify-content: space-between;
} */
.profile_artist .profilSdeBar {
  border-radius: 10px;
  box-shadow: 0 3px 30px 0 rgb(0 0 0 / 10%);
  background-color: #35353587;
  padding: 32px 0;
}
.profile_artist .profilSdeBar--sticky {
  position: sticky;
  top: 15px;
}
.profile_artist .proflPicContanr {
  width: 160px;
  height: 160px;
  /* background-image: linear-gradient(45deg, #ace143, #219393); */
  background-position: center;
  background-size: cover;
  border-radius: 8px;
  position: relative;
}
.profile_artist .profilSdeBar .proflPicContanr {
  margin: 0 auto;
}
.profile_artist .proflPicContanr__nmeIniContanr {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.profile_artist .proflPicContanr__nmeIni {
  font-size: calc((1, 1) * 40px);
}
.profile_artist .latoBlack {
  font-weight: 900;
}

.profile_artist .whiteText,
.profile_artist ._aff .whiteText {
  color: #ffffff;
}
.profile_artist .proflPicContanr__editGreyBgIcn {
  position: absolute;
  bottom: 17px;
  right: -20px;
  z-index: 1;
  overflow: hidden;
}
.profile_artist .myPrfilSprit {
  background-image: url(https://imgak.mmtcdn.com/pwa_v3/pwa_commons_assets/desktop/usersProfile/myProfileSprite4.png);
  background-size: 200px 225px;
  background-repeat: no-repeat;
  display: inline-block;
  flex-shrink: 0;
}
.profile_artist .myPrfilSprit__editGreyBg {
  width: 37px;
  height: 37px;
  background-position: -4px -31px;
}
.profile_artist .proflPicContanr__inputUpload {
  position: absolute;
  width: 100%;
  height: 200%;
  outline: 0;
  border: 0;
  z-index: 1;
  opacity: 0;
  cursor: pointer;
  bottom: 0;
}
.profile_artist .profilSdeBar__contntWrap {
  text-align: center;
  padding: 0 20px;
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.profile_artist .latoBlack {
  font-weight: 900;
}
.profile_artist .font20 {
  font-size: calc((1, 1) * 20px);
  line-height: 20px;
}
.profile_artist .blackText,
.profile_artist ._aff .blackText {
  color: #fff;
}
.profile_artist .font12 {
  font-size: calc((1, 1) * 12px);
  line-height: 12px;
}
.profile_artist .profilSdeBar .sdeBarNav {
  margin: 25px 20px 0;
}
.profile_artist .font14 {
  font-size: calc((1, 1) * 14px);
  line-height: 14px;
}
.profile_artist .latoBold {
  font-weight: 700;
}
.profile_artist .sdeBarNav__navItm-active {
  border-radius: 4px;
  background: rgb(0 140 255 / 10%);
  color: #d1507f;
}
.profile_artist .sdeBarNav__navItm {
  display: flex;
  align-items: center;
  padding: 10px 0 10px 27px;
  cursor: pointer;
}
.profile_artist .sdeBarNav__iconContnr {
  width: 24px;
  height: 24px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  align-self: flex-start;
  margin-right: 16px;
}
.profile_artist .myPrfilSprit {
  background-image: url(https://imgak.mmtcdn.com/pwa_v3/pwa_commons_assets/desktop/usersProfile/myProfileSprite4.png);
  background-size: 200px 225px;
  background-repeat: no-repeat;
  display: inline-block;
  flex-shrink: 0;
}
.profile_artist .myPrfilSprit__person {
  width: 20px;
  height: 20px;
  background-position: -127px -5px;
}
.profile_artist .sdeBarNav__navItm-active .myPrfilSprit__person {
  background-position: -127px -33px;
}
.profile_artist .myProfilePage .rightSecWrapper {
  margin-bottom: 83vh;
}
.profile_artist .rightSecWrapper {
  /* width: 877px; */
  flex-shrink: 0;
}
.profile_artist .profilCompont {
  border-radius: 10px;
  border: solid 1px rgb(0, 0, 0) 87;
  background-color: #35353587;
  padding: 20px 30px 2px;
}
.profile_artist .cm__myTripSideBar {
  position: relative;
}
.profile_artist .cm__myProfileTheme {
  margin-bottom: 12px;
}
.profile_artist .cm__myTripSideBar::before {
  content: "";
  height: 70px;
  background-color: #adadad;
  position: absolute;
  left: 0;
  border-left: 4px solid #c4c4c4;
}
.profile_artist .detalCrdHadr {
  display: flex;
  justify-content: space-between;
}
.profile_artist .appendRight10 {
  margin-right: 10px;
}
.profile_artist .font30 {
  font-size: calc((1, 1) * 30px);
  line-height: 30px;
}
.profile_artist .font18 {
  font-size: calc((1, 1) * 18px);
  line-height: 18px;
}
.profile_artist .darkGreyText,
.profile_artist ._aff .darkGreyText {
  color: #fff;
}
.profile_artist .appendTop10 {
  margin-top: 10px !important;
}
.profile_artist .btn__dtailAdEdt {
  font-family: inherit;
  font-weight: 900;
  display: flex;
  align-items: center;
  padding: 10px 16px;
  background: #35353587;
  border-radius: 8px;
  border: 1px solid #cacaca;
  min-width: 90px;
  outline: 0;
  cursor: pointer;
  margin-left: auto;
  line-height: 20px;
  text-transform: uppercase;
  font-size: calc(var(--font-scale, 1) * 14px);
  color: #d1507f !important;
}
.profile_artist .blueText,
.profile_artist ._aff .blueText {
  color: #d1507f;
}
.profile_artist .myPrfilSprit {
  background-image: url(https://imgak.mmtcdn.com/pwa_v3/pwa_commons_assets/desktop/usersProfile/myProfileSprite4.png);
  /* background-size: 200px 225px; */
  background-repeat: no-repeat;
  display: inline-block;
  flex-shrink: 0;
}
.profile_artist .myPrfilSprit__edtIcn {
  width: 15px;
  height: 15px;
  background-position: -8px -5px;
}
.profile_artist .appendRight5 {
  margin-right: 5px;
}
.profile_artist .profilCompont__dtalLst {
  /* margin: 35px -30px 0; */
  margin-block: 35px;
  border-radius: 0 0 10px 10px;
  overflow: hidden;
}
.profile_artist .profilCompont__dtalLstItmContnt {
  display: flex;
  align-items: center;
  padding: 18px 0;
  margin-left: 30px;
  /* border-top: 1px solid #e3e3e3; */
  height: 55px;
}
.profile_artist
  .profilCompont__dtalLstItm:first-child
  .profilCompont__dtalLstItmContnt {
  border-top: 0;
}
.profile_artist .font11 {
  font-size: calc((1, 1) * 11px);
  line-height: 11px;
}
.profile_artist .profilCompont__detalkey {
  width: 160px;
  margin-right: 20px;
}
.profile_artist .profilCompont__detalkey2 {
  width: 250px;
  margin-right: 20px;
}
.profile_artist .blackText,
.profile_artist ._aff .blackText {
  color: #fff;
}
.profile_artist .font16 {
  font-size: calc((1, 1) * 16px);
  line-height: 16px;
}
.profile_artist .appendRight5 {
  margin-right: 5px;
}
.profile_artist .lognDtalComp {
  border-radius: 10px;
  border: solid 1px #00000087;
  background-color: #35353587;
  padding: 20px 30px 2px;
}
.profile_artist .cm__myTripSideBar {
  position: relative;
}
.profile_artist .cm__myProfileTheme {
  margin-bottom: 12px;
}
.profile_artist .cm__myTripSideBar::before {
  content: "";
  height: 70px;
  background-color: #adadad;
  position: absolute;
  left: 0;
  border-left: 4px solid #c4c4c4;
}
.profile_artist .font18 {
  font-size: calc((1, 1) * 18px);
  line-height: 18px;
}
.profile_artist .lognDtalComp__dtalLst {
  /* margin: 35px -30px 0; */
  margin-block: 35px;
  border-radius: 0 0 10px 10px;
  overflow: hidden;
}
.profile_artist .lognDtalComp__dtalLstItmContnt {
  display: flex;
  align-items: center;
  padding: 18px 0;
  margin-left: 30px;
  /* border-top: 1px solid #e3e3e3; */
  height: 55px;
}
.profile_artist
  .lognDtalComp__dtalLstItm:first-child
  .lognDtalComp__dtalLstItmContnt {
  border-top: 0;
}
.profile_artist .lognDtalComp__detalkey {
  width: 160px;
  margin-right: 20px;
}
.profile_artist .greenText,
.profile_artist ._aff .greenText {
  color: #1a7971;
}
.profile_artist .makeFlex {
  display: flex;
}
.profile_artist .noShrink {
  flex-shrink: 0;
}
.profile_artist .greenText {
  --borderColor: #00a19c;
}
.profile_artist .makeFlex.hrtlCenter {
  align-items: center;
}
.profile_artist .appendLeft10 {
  margin-left: 10px;
}
.profile_artist .myPrfilSprit {
  background-image: url(https://imgak.mmtcdn.com/pwa_v3/pwa_commons_assets/desktop/usersProfile/myProfileSprite4.png);
  background-size: 200px 225px;
  background-repeat: no-repeat;
  display: inline-block;
  flex-shrink: 0;
}
.profile_artist .lognDtalComp__editDeltContanr {
  margin-left: auto;
  margin-right: 30px;
  display: none;
  align-items: center;
}
.profile_artist .lognDtalComp__dltBtn {
  position: relative;
  outline: 0;
}
.profile_artist .appendLeft15 {
  margin-left: 15px;
}
.profile_artist .myPrfilSprit {
  background-image: url(https://imgak.mmtcdn.com/pwa_v3/pwa_commons_assets/desktop/usersProfile/myProfileSprite4.png);
  background-size: 200px 225px;
  background-repeat: no-repeat;
  display: inline-block;
  flex-shrink: 0;
}
.profile_artist .myPrfilSprit__delete {
  width: 18px;
  height: 18px;
  background-position: -29px -5px;
}
.profile_artist .tooltip {
  width: 338px;
  border-radius: 4px;
  box-shadow: 0 11px 50px 0 rgb(0 0 0 / 22%);
  background-color: #fff;
  padding: 15px 35px;
  z-index: 10;
}
.profile_artist .lognDtalComp__dltBtn .tooltip {
  position: absolute;
  top: 36px;
  right: -23px;
  display: none;
}
.profile_artist .appendLeft30 {
  margin-left: 30px;
}
.profile_artist .appendRight30 {
  margin-right: 30px;
}
.profile_artist .cursorPointer {
  cursor: pointer;
}
.profile_artist .redText {
  --borderColor: #eb2026;
}
.profile_artist .redText,
.profile_artist ._aff .redText {
  color: #d0021b;
}
.profile_artist .lognDtalComp__pswrdTxt {
  align-self: flex-start;
  line-height: 0;
  font-size: 25px;
}
.profile_artist .pushRight {
  margin-left: auto;
}
.popupCtn {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1001;
  background-color: rgb(0 0 0 / 70%);
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: auto;
}
.popupCtn .profilEdtFomCrd {
  border-radius: 10px;
  box-shadow: 0 1px 7px 0 rgb(0 0 0 / 40%);
  background-color: #fff;
  padding: 24px 30px 30px;
  width: 510px;
  position: relative;
}
.popupCtn .profilEdtFomCrd__formContanr {
  margin-top: 35px;
}
.popupCtn .travlrFomCrd__formTab:first-child {
  margin-top: 0;
}
.popupCtn .travlrFomCrd__formTab:last-child {
  border-bottom: 0;
  padding-bottom: 0;
}
.popupCtn .profilEdtFomCrd__fieldRow {
  display: flex;
  gap: 20px;
  margin-top: 20px !important;
}
.popupCtn .profilEdtFomCrd__fieldColumn {
  width: 215px;
}
.popupCtn .txtFild {
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  width: 100%;
}
.popupCtn .font14 {
  font-size: 14px;
  line-height: 14px;
}
.popupCtn .txtFild__lablAstrk {
  font-size: 16px;
  margin-left: 2px;
}
.popupCtn .txtFild__textInputContanr {
  border-radius: 4px;
  border: solid 1px #9b9b9b;
  margin-top: 8px;
  position: relative;
  width: 100%;
}
.popupCtn .txtFild__textInput {
  border-radius: 4px;
  padding: 12px 16px !important;
  outline: 0;
  /* border: none; */
  width: 100%;
  font-family: Lato, sans-serif;
}
.popupCtn .dropDown__elmntContanr {
  position: relative;
  /* margin-top: 8px; */
}
.popupCtn .dropDown__valueBox {
  border-radius: 4px;
  /* border: solid 1px #9b9b9b; */
  cursor: pointer;
  padding: 12px 30px 12px 16px;
  outline: 0;
  position: relative;
  height: 43px;
  background: #212529;
}
/* .popupCtn .dropDown__valueBox:focus{
    box-shadow: none;
    border: unset;
} */
.popupCtn .dropDown__valueBox:first-child {
  margin-top: 0;
}
.popupCtn .dropDown__valueBox::before {
  content: "";
  width: 9px;
  height: 9px;
  border: solid #747474;
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
  position: absolute;
  right: 16px;
  top: 15px;
  box-sizing: border-box;
}
.popupCtn .profilEdtFomCrd__footer {
  display: flex;
  align-items: center;
  margin-top: 30px;
}
.popupCtn .btn__primry {
  font-family: inherit;
  padding: 13px 20px;
  border-radius: 4px;
  background-color: #d1507f !important;
  border: 0;
  outline: 0;
  text-align: center;
  display: inline-block;
  text-transform: uppercase;
  cursor: pointer;
  flex-shrink: 0;
  min-width: 135px;
  position: relative;
}
.popupCtn .appendRight30 {
  margin-right: 30px;
}
.popupCtn .pushRight {
  margin-left: auto;
}
.profile_artist .list-group {
  --bs-list-group-bg: #000 !important;
  --bs-list-group-color: #fff !important;
}
.subscription-tab .list-group {
  --bs-list-group-bg: transparent !important;
  --bs-list-group-color: #fff !important;
}
.popupCtn .modal-header {
  background-color: #d1507f !important;
  color: white !important;
}
.popupCtn .modal_btn {
  background-color: #d1507f !important;
  border: none !important;
}
.popupCtn .form-label {
  color: #fff;
}

.nav-tabs .nav-link {
  color: #fff;
  border: none;
}
.nav-tabs .nav-link.active {
  background-color: #35353587;
  color: #fff;
  border: 1px solid white;
}

.subscription-tab .tabSection {
  font-size: 1.3em;
  margin-bottom: 40px;
}
.plan_renewal_card__container {
  display: flex;
}
.plan_renewal_card__image {
  padding-right: 1em;
  width: 100px;
  height: 100%;
  vertical-align: middle;
}
.subscription-tab .list-group-item {
  border: none;
}
.plan_renewal_card__details {
  display: flex;
}
.plan_renewal_card__details_left_header {
  padding-bottom: 1em;
}
.plan_renewal_card__details_li_label {
  font-weight: 700;
}
.tooltip-info-icon {
  color: #1293d1;
}
/* .tooltip-info-icon:before {
  color: var(--plan-color-1);
}
.tooltip-info-icon:before {
  font-family: FontAwesome;
  content: "";
} */
.plan_renewal_card__details svg {
  color: violet;
}
.included {
  border-top-right-radius: 5px;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
  border-top-left-radius: 5px;
  background-color: #f0f0f0;
  margin-bottom: 20px;
}
.included {
  background-color: #282828;
  color: rgba(255, 255, 255, 0.8);
}
.upgrade-block {
  border: 1px solid rgba(255, 255, 255, 0.2);
}
.upgrade-block {
  font-family: Roboto, sans-serif;
  background: #141414;
  color: white;
  padding: 20px;
  border-radius: 10px;
}
.upgrade-block .heading {
  font-weight: 900;
  font-size: 1.25rem;
  line-height: 120%;
  margin-bottom: 20px;
}
.emphasis {
  color: violet;
}
.text-center {
  text-align: center;
}
.upgrade-block .cta {
  border: 2px solid violet;
  color: violet;
  padding: 5px 10px;
  border-radius: 50px;
  font-weight: 700;
  letter-spacing: 3px;
  font-size: 12px;
  text-transform: uppercase;
  text-align: center;
  transition: all 100ms ease-out;
  text-decoration: none !important;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  background-color: transparent;
}
.upgrade-block .cta .record {
  width: 20px;
  min-width: 20px;
  height: auto;
  margin-right: 5px;
  line-height: normal;
}
.record-path {
  fill: violet;
}
.included h3 {
  border-top-right-radius: 5px;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
  border-top-left-radius: 5px;
  font-weight: 700;
  font-size: 1em;
  background-color: #e6e6e6;
  border-bottom: 1px dotted #999;
  color: #333;
  padding: 10px;
}
.included h3 {
  background-color: var(--dm-accent-color-1);
  color: var(--dm-primary-font-color);
}
.included ul li {
  padding: 10px;
  border-bottom: 1px dotted #999;
}
.box {
  background-color: #f0f0f0;
  border: 1px solid #e6e6e6;
  border-radius: 5px;
  margin-bottom: 10px;
  padding: 10px;
  position: relative;
}
.box {
  color: #fff;
  background-color: #282828;
  border: 1px solid #282828;
}
.box .summary {
  float: left;
}
.box .summary h3 {
  font-size: 1.6em;
  margin-bottom: 5px;
}
.box .summary p {
  line-height: 1.5em;
  margin: 0;
}
.box .details {
  float: right;
  text-align: right;
}
.box .details p {
  margin: 0;
}
.box .details .price {
  position: relative;
  font-size: 1.8em;
  font-weight: 700;
}
.box .blue-btn,
.box .blue-btn-wrapper {
  bottom: 0;
  clear: both;
  float: right;
  position: relative;
  right: 0;
  display: flex;
  gap: 10px;
}
.blue-btn,
.blue-button,
.button-callout-alt,
form input[type="submit"],
.payoneer-signup-banner a,
.sign_up_now_button {
  border-radius: 99em;
  border: none;
  cursor: pointer;
  font-size: 13px;
  font-weight: 700;
  height: fit-content;
  letter-spacing: 1px;
  padding: 1em 1.5em;
  text-decoration: none;
  width: fit-content;
  white-space: nowrap;
  text-transform: uppercase;
  user-select: none;
  border: none;
  outline: 0 solid transparent;
  outline-offset: 0;
  background: linear-gradient(270deg, #ff6600 0%, #7b16d9 100%);
  color: #ffffff;
  text-decoration: none !important;
}

@media (min-width: 768px) {
  .leftSecWrapper {
    position: sticky;
    top: 100px;
    left: 0px;
    height: fit-content;
  }
}

.profile_artist .list-group-item{
  border-color: #252323;
}