.page-container {
  padding-top: 90px !important;
  padding-bottom: 120px;
  background-color: #000;
}
.page-title {
  font-weight: 300;
  font-size: 5rem;
  color: #093c59;
  text-align: center;
  text-transform: uppercase;
  padding: 3.125rem 0 6.25rem;
}
.page-contact {
  padding-bottom: 6.25rem;
}
.page-contact .nav-tabs {
  max-width: 1000px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  padding: 0 0 5rem;
}
.page-contact .nav-tabs .nav-item {
  flex: 0 0 48%;
  border: 1px solid #bdbdbd;
  box-shadow: 0 0 20px 2px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  text-align: center;
  font-size: 2.125rem;
  line-height: 1.3;
  color: #093c59;
}
.page-payment .nav-tabs .nav-item {
  flex: 0 0 24%;
  border: 1px solid #bdbdbd;
  box-shadow: 0 0 20px 2px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  text-align: center;
  font-size: 1.125rem;
  line-height: 0.6;
  color: #093c59;
}
.page-contact .nav-tabs .nav-item .nav-link.active {
  color: #f2f2f2;
  background: #5489a3;
  border-radius: 10px;
  border: none;
  background: linear-gradient(270deg, #ff6600 0%, #7b16d9 100%);
}
.page-contact .nav-tabs .nav-item .nav-link {
  width: 100%;
  height: 100%;
  /* padding: 0px; */
  padding: 2.5rem 15px;
  margin: 0px;
}

.page-contact .tab-content {
  max-width: 800px;
  margin: 0 auto;
  /* display: none; */
}
.page-contact .tab-content .item {
  border-bottom: 1px solid #bdbdbd;
}
.page-contact .tab-content .accordion-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
}
.page-contact .tab-content .accordion-item {
  border: none;
  box-shadow: none;
}
.page-contact .tab-content .accordion-header button {
  /* font-family: PantonNarrow, Helvetica, Arial; */
  font-size: 1.375rem;
  line-height: 1.3;
  color: #fff;
  padding: 1.25rem 10px;
  /* background-color: #fff; */
  border: none;
  background: #000;
  border-bottom: 1px solid #bdbdbd;
}
.page-contact .tab-content .accordion-header button:focus {
  border: none;
}

.page-contact .tab-content .accordion-header button::after {
  color: #fff;
}

.page-contact .tab-content .item-content {
  padding: 1.25rem 2.5rem;
  /* font-family: PantonNarrow, Helvetica, Arial; */
  font-size: 1rem;
  line-height: 1.4;
  color: #4f4f4f;
  /* display: none; */
}
.page-contact .tab-content .item-content a {
  color: #ff6c3e;
}

.page-contact .tab-content .form-control {
  border: 1px solid #bdbdbd;
  border-radius: 5px;
  background: none;
  padding: 10px 20px;
  margin-bottom: 10px;
}
.page-contact .tab-content .form-control::placeholder {
  color: #ffffff7d;
}
.page-contact .tab-content .accordion-body {
  background: #000;
  color: #fff;
}

.page-contact .tab-content .submit_btn {
  color: #fff;
  border-radius: 50px !important;
  border: 4px double transparent;
  background-image: linear-gradient(rgb(0, 0, 0), rgb(0, 0, 0)),
    radial-gradient(circle at left top, rgb(1, 110, 218), rgb(217, 0, 192));
  background-origin: border-box;
  background-clip: padding-box, border-box;
}
@media screen and (max-width: 991px) {
  .page-title {
    font-size: 3.75rem;
  }
  .page-contact .nav-tabs .nav-item {
    font-size: 1.75rem;
  }
}
@media screen and (max-width: 599px) {
  .page-title {
    font-size: 2.5rem;
  }
  .page-contact .nav-tabs .nav-item {
    font-size: 1.25rem;
  }
}
